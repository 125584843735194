import { Box, Button, Chip, CircularProgress, Fade, Typography } from '@mui/material'
import React, { useState, useContext, useEffect, useRef } from 'react'
import Main from '../components/Main';
import MainHeader from '../components/MainHeader'

import { AuthContext } from '../utils/AuthContext';

import SelectInsurers from '../components/SelectInsurers';
import { toast } from 'react-toastify';
import InsurerTable from '../components/Lifeline_Rates';
import ClientsInputTable1 from '../components/ClientsInputTable1';
import { downloadTOB, getQuotation } from '../services/QuotationService';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import countries from '../components/Countries_Data';
import DownloadIcon from '@mui/icons-material/Download';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { addQuotationData, fetchQuotationData, updateQuotationData } from '../services/QuotationDataService';
import { useSearchParams } from 'react-router-dom';
import AlertDialog from '../components/AlertDialog';
import CustomSelect2 from '../components/CustomSelect2';
import BWHO_Rates from '../components/BWHO_Rates';
import { fetchAppConfig } from '../services/AppConfigService';
import { ConfigContext } from '../utils/ConfigContext';
import Company_Rates from '../components/Company_Rates';
import SimpleFade from '../components/SimpleFade';
import Download_TOB_FormDialog from '../components/Download_TOB_Form';
import OilGas_Rates from '../components/OilGas_Rates';

function Quotation() {

  const { auth } = useContext(AuthContext);
  const { appConfig, setAppConfig } = useContext(ConfigContext);

  const [open, setOpen] = useState(localStorage.getItem('quoting-drawer-open') == 'true');

  const currencies = appConfig.content.currencies || [];
  const area_of_coverages = appConfig.content.area_of_coverages || [];
  const relationships = appConfig.content.relationships || [];

  const [currency, setCurrency] = useState(currencies[0]);
  const [paymentFrequency, setPaymentFrequency] = useState("Annually");
  const [areaOfCoverage, setAreaOfCoverage] = useState(area_of_coverages[0]);
  const [countryOfResidence, setCountryOfResidence] = useState('');

  const [rowData, setRowData] = useState([]);
  const [quotationData, setQuotationData] = useState();

  const defaultPlans = appConfig.content.default_plans || [];
  const [selectedPlans, setSelectedPlans] = useState(defaultPlans);

  const [showResult, setShowResult] = useState(false);
  const [fetchingDataQuotation, setFetchingDataQuotation] = useState(false);
  const [quotationDownloading, setQuotationDownloading] = useState(false);
  const [clearQuotationConfirmation, setClearQuotationConfirmation] = useState(false);
  const [tobNotes, setTobNotes] = useState('');

  const [assistanceCovers, setAssistanceCovers] = useState({
    evacuation: false,
    repatriation: false
  });

  const [lifelineLoading, setLifelineLoading] = useState(false);
  const [bwho_Loading, set_bwho_Loading] = useState(false);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [oilGasLoading, setOilGasLoading] = useState(false);

  const [quotationSaving, setQuotationSaving] = useState(false);

  const [showQuotationDataFormDialog, setShowQuotationDataFormDialog] = useState(false);

  const [showDownload_TOB_FormDialog, setShowDownload_TOB_FormDialog] = useState(false);

  const [recommendedPlans, setRecommendedPlans] = useState([]);

  const gridRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();

  const resultRef = useRef(null)
  const lifelineRef = useRef();
  const bwhoRef = useRef();
  const companyRef = useRef();
  const oilGasRef = useRef();

  const defaultInsurers = {
    lifeline: false,
    bwho: false,
    bupa: false,
    company: false,
    oilGas: false,
    firstChoice: false,
    vumi: false,
    optimumGlobal: false,
    myHealthSg: false,
    ihhp: false,
    allianzSg: false
  };

  const [insurers, setInsurers] = useState(defaultInsurers);

  const { lifeline, bwho, bupa, company, oilGas, firstChoice, vumi, optimumGlobal, myHealthSg, ihhp, allianzSg } = insurers;

  useEffect(() => {
    setRowData([getNewEmptyRow({ relationship: 'Main Applicant' })]);

    activateFirstColumn();
  }, []);

  const getNewEmptyRow = (val = {}) => {
    return {
      full_name: "",
      age: "",
      gender: "",
      area_of_coverage: areaOfCoverage,
      nationality: "",
      country_of_residence: countryOfResidence,
      currency: currency,
      payment_frequency: paymentFrequency,
      relationship: "",
      deductible: "",
      ...val
    };
  }

  function addNewClient() {
    if (rowData.length >= 10) {
      toast.info('You can only add up to 5 clients', {
        autoClose: 1500,
        hideProgressBar: true,
      });
      return;
    }

    gridRef.current.api.clearFocusedCell();

    setRowData(prev => {
      return [...prev, getNewEmptyRow()];
    });

    activateFirstColumn();
  }

  const activateFirstColumn = () => {
    setTimeout(() => {
      gridRef.current.api.ensureIndexVisible(gridRef.current.api.getDisplayedRowCount() - 1);

      var firstEditCol = gridRef.current.columnApi.getAllDisplayedColumns()[0];
      gridRef.current.api.ensureColumnVisible(firstEditCol);

      setTimeout(() => {
        gridRef.current.api.startEditingCell({
          rowIndex: gridRef.current.api.getDisplayedRowCount() - 1,
          colKey: 'full_name'
        });
      }, 100);
    }, 100);
  }

  useEffect(() => {
    handleUpdateClientsData();
  }, [currency, paymentFrequency, areaOfCoverage, countryOfResidence]);



  function handleUpdateClientsData() {
    if (rowData.length == 0) {
      return;
    }

    rowData.forEach(client => {
      client.currency = currency;
      client.payment_frequency = paymentFrequency;
      client.area_of_coverage = areaOfCoverage;
      client.country_of_residence = countryOfResidence;
    });

    setRowData([...rowData]);
  }

  function getHandleGetQuotationFns() {
    const fnArr = {
      'lifeline': lifelineRef.current.handleGetQuotation,
      'bwho': bwhoRef.current.handleGetQuotation,
      'company': companyRef.current.handleGetQuotation,
      'oilGas': oilGasRef.current.handleGetQuotation
    };

    return fnArr;
  }

  function fetchQuotationByKey(key) {
    const fnArr = getHandleGetQuotationFns();

    fnArr[key]();
  }

  function fetchAllQuotations() {
    const fnArr = getHandleGetQuotationFns();

    for (const key in insurers) {
      if (Object.hasOwnProperty.call(insurers, key)) {
        const value = insurers[key];

        if (value !== true) { continue; }

        fnArr[key]();
      }
    }
  }

  function resetAllDeductibles() {
    const fnArr = {
      'lifeline': lifelineRef.current.resetDeductible,
      'bwho': bwhoRef.current.resetDeductible,
      'company': companyRef.current.resetDeductible,
      'oilGas': oilGasRef.current.resetDeductible,
    };

    for (const key in insurers) {
      if (Object.hasOwnProperty.call(insurers, key)) {
        const value = insurers[key];

        if (value !== true) { continue; }

        fnArr[key]();
      }
    }
  }

  function setAllLoading(loading) {

    const fnArr = {
      'lifeline': setLifelineLoading,
      'bwho': set_bwho_Loading,
      'company': setCompanyLoading,
      'oilGas': setOilGasLoading,
    };

    for (const key in insurers) {
      if (Object.hasOwnProperty.call(insurers, key)) {
        const value = insurers[key];

        if (value !== true) { continue; }

        fnArr[key](loading);
      }
    }

  }

  function isAnyLoading() {
    return lifelineLoading || bwho_Loading;
  }

  function validateForm() {
    let isValid = true;

    if (currency.trim() === '') {
      toast.error('Please select currency', {
        autoClose: 1000,
        hideProgressBar: true,
      });
      return false;
    }

    if (paymentFrequency.trim() === '') {
      toast.error('Please select payment frequency', {
        autoClose: 1000,
        hideProgressBar: true,
      });
      return false;
    }

    for (let i = 0; i < rowData.length; i++) {
      // full name
      if (rowData[i].full_name == null || rowData[i].full_name.trim() === '') {
        isValid = false;
        break;
      }


      // age
      if (rowData[i].age == null || rowData[i].age == '') {
        isValid = false;
        break;
      }

      if (typeof rowData[i].age === 'string') {
        if (isNaN(rowData[i].age.trim())) {
          toast.error('Please enter a valid age', {
            autoClose: 1000,
            hideProgressBar: true,
          });
          return false;
        }
      }

      // gender
      if (rowData[i].gender == null) {
        isValid = false;
        break;
      }

      // area of coverage
      if (rowData[i].area_of_coverage.trim() == null) {
        isValid = false;
        break;
      }

      // nationality
      if (rowData[i].nationality == null) {
        isValid = false;
        break;
      }

      if (!countries.includes(rowData[i].nationality.trim())) {
        toast.error('Please enter a valid nationality', {
          autoClose: 1000,
          hideProgressBar: true,
        });
        return false;
      }

      // country of residence
      if (rowData[i].country_of_residence == null) {
        isValid = false;
        break;
      }

      if (!countries.includes(rowData[i].country_of_residence.trim())) {
        toast.error('Please enter a valid country of residence', {
          autoClose: 1000,
          hideProgressBar: true,
        });
        return false;
      }


      // relationship
      if (rowData[i].relationship == null || rowData[i].relationship.trim() === '') {
        isValid = false;
        break;
      }
    }

    if (!isValid) {
      toast.error('Please fill up all fields', {
        autoClose: 1000,
        hideProgressBar: true,
      });
      return;
    }

    return isValid;
  }

  function validateIfInsurerSelected() {
    for (const [key, value] of Object.entries(insurers)) {
      if (insurers[key] === true) {
        return true;
      }
    }

    toast.error('Please select atleast one insurer', {
      autoClose: 1000,
      hideProgressBar: true,
    });

    return false;
  }

  function handleDownloadTOB() {
    if (quotationDownloading) { return; }


    if (selectedPlans.length == 0) {
      toast.error('Please select a plan', {
        autoClose: 1500,
        hideProgressBar: true,
      });

      return;
    }

    const id = toast.loading("Downloading...");

    setQuotationDownloading(true);

    downloadTOB({
      token: auth.token, body: {
        clients: rowData,
        currency: currency,
        payment_frequency: paymentFrequency,
        area_of_coverage: areaOfCoverage,
        selected_plans: filterSelectedInsurerPlan(),
        assistance_covers: assistanceCovers,
        recommended_plans: recommendedPlans,
        tob_notes: tobNotes
      }
    }).then((response) => {
      toast.update(id, {
        type: 'success',
        render: 'Quotation Downloaded',
        autoClose: 1500,
        hideProgressBar: true,
        isLoading: false
      });

      setQuotationDownloading(false);
      const str = response.headers['content-disposition'];
      console.log(str);
      const match = str.match(/filename="([^"]+)"/);
      let filename = 'Quotation.pdf'
      if (match) {
        filename = match[1];
      } else {
        console.log('Filename not found.');
      }

      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', filename); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);


    }).catch(err => {
      console.log(err);

      toast.update(id, {
        type: 'error',
        render: 'An Error Has Occurred',
        autoClose: 1500,
        hideProgressBar: true,
        isLoading: false
      });

      setQuotationDownloading(false);
    })
  }

  function filterSelectedInsurerPlan() {
    const filteredSelectedPlans = [];

    const lifelinePlans = ['lifeline_essential', 'lifeline_classic', 'lifeline_gold'];
    const bwhoPlans = ['bwho_wmi', 'bwho_wme', 'bwho_wmp', 'bwho_ww'];
    const companyPlans = ['company_essential', 'company_classic', 'company_gold', 'company_gold_superior'];
    const oilGasPlans = ['oil_gas_essential', 'oil_gas_classic', 'oil_gas_gold'];

    selectedPlans.forEach(selectedPlan => {
      if (lifeline && lifelinePlans.includes(selectedPlan)) {
        filteredSelectedPlans.push(selectedPlan);
      } else if (bwho && bwhoPlans.includes(selectedPlan)) {
        filteredSelectedPlans.push(selectedPlan);
      } else if (company && companyPlans.includes(selectedPlan)) {
        filteredSelectedPlans.push(selectedPlan);
      } else if (oilGas && oilGasPlans.includes(selectedPlan)) {
        filteredSelectedPlans.push(selectedPlan);
      }
    });

    return filteredSelectedPlans;
  }



  function handleSaveQuotationData() {
    const isValid = validateForm();

    if (!isValid) { return; }

    if (quotationData == null) {
      handleAddQuotationData();
    } else {
      handleUpdateQuotationData();
    }
  }

  function getSelectedInsurers(obj) {
    const selectedInsurers = [];
    for (const key in obj) {
      if (obj[key] === true) {
        selectedInsurers.push(key);
      }
    }
    return selectedInsurers;
  }

  async function handleAddQuotationData() {
    if (quotationSaving == true) {
      //prevent clicking while saving...
      return;
    }

    setQuotationSaving(true);

    const id = toast.loading("Saving Data...");

    const response = await addQuotationData({
      token: auth.token, body: {
        clients: rowData,
        selected_insurers: getSelectedInsurers(insurers),
        selected_plans: selectedPlans
      }
    });

    toast.update(id, {
      type: 'success',
      render: 'Quotation Data Saved',
      autoClose: 1500,
      hideProgressBar: true,
      isLoading: false
    });

    const quotation = response.data.quotation;

    setQuotationData(response.data.quotation);

    searchParams.set('id', quotation.id);
    setSearchParams(searchParams);

    setQuotationSaving(false);
  }

  async function handleUpdateQuotationData() {
    setQuotationSaving(true);

    const id = toast.loading("Saving Data...")

    const response = await updateQuotationData({
      quotationId: quotationData.id,
      token: auth.token,
      body: {
        clients: rowData,
        selected_insurers: getSelectedInsurers(insurers),
        selected_plans: selectedPlans
      }
    });

    toast.update(id, {
      type: 'success',
      render: 'Quotation Data Updated',
      autoClose: 1500,
      hideProgressBar: true,
      isLoading: false
    });

    setQuotationData(response.data.quotation);

    setQuotationSaving(false);
  }

  useEffect(() => {
    const id = searchParams.get('id');

    if (id !== null && id !== '') {
      handleFetchQuotationData(id);
    }

    handleFetchAppConfig();

  }, []);

  async function handleFetchQuotationData(id) {
    setFetchingDataQuotation(true);

    const response = await fetchQuotationData({ token: auth.token, quotationId: id });

    const clients = response.data.quotation.clients;

    setQuotationData(response.data.quotation);
    setRowData(clients);

    const selected_insurers = response.data.quotation.selected_insurers;
    const selected_plans = response.data.quotation.selected_plans;

    let insurers = defaultInsurers;

    selected_insurers.forEach(selected_insurer => {
      insurers[selected_insurer['name']] = true;
    });

    let selectedPlans = [];

    selected_plans.forEach(selected_plan => {
      selectedPlans.push(selected_plan.name);
    });

    setInsurers(insurers);
    setSelectedPlans(selectedPlans);
    setCurrency(clients[0]['currency']);
    setPaymentFrequency(clients[0]['payment_frequency']);

    setFetchingDataQuotation(false);
  }

  function handleNewQuotation() {
    // empty clients
    setRowData([getNewEmptyRow({ relationship: 'Main Applicant' })]);

    // delete query params
    searchParams.delete('id');
    setSearchParams(searchParams);

    // addNewClient();

    setInsurers(defaultInsurers);

    setShowResult(false);
    setSelectedPlans(defaultPlans);
    setQuotationData(null);

    toast.success('Quotation Form Cleared', {
      autoClose: 1500,
      hideProgressBar: true,
    });
  }

  function handleViewRatesButtonClicked() {
    gridRef.current.api.clearFocusedCell();
    handleUpdateClientsData();

    const isValid = validateForm();

    if (!isValid) {
      return;
    }

    const hasSelected = validateIfInsurerSelected();

    if (!hasSelected) {
      return;
    }

    setShowResult(true);

    setTimeout(() => {
      fetchAllQuotations();
    }, 100);
  }

  async function handleFetchAppConfig() {
    const res = await fetchAppConfig({ token: auth.token });

    const config = res.data;

    const configCurrentTimestamp = localStorage.getItem('quoting-app-config-timestamp');

    if (configCurrentTimestamp !== config.timestamp) {
      localStorage.setItem('quoting-app-config-timestamp', config.timestamp);
      localStorage.setItem('quoting-app-config', config.content);

      setAppConfig({
        content: JSON.parse(config.content),
        configTimestamp: config.timestamp
      });
    }
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <MainHeader open={open} setOpen={setOpen} />
      <Main open={open} sx={{ pt: 7 }} >

        <Box sx={{ display: showResult === true ? 'none' : 'block' }}>
          <Typography variant='h4' sx={{ fontWeight: 600, mt: 4, mb: 2 }}>Quotation
            {
              quotationData != null &&
              <Chip
                color='success'
                icon={<BookmarkIcon />}
                label={quotationData.name}
                sx={{ ml: 2 }}
                onDelete={() => {
                  setClearQuotationConfirmation(true);
                }}></Chip>
            }

          </Typography>
          <Box sx={{ display: fetchingDataQuotation === true ? 'block' : 'none' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
              <CircularProgress />

            </Box>

          </Box>
          <Box sx={{ display: fetchingDataQuotation === true ? 'none' : 'block' }}>
            {/* <Box sx={{ display: 'block', width: open ? '80vw' : '96vw' }}> */}
            <Box>
              <Box sx={{ display: 'flex', justifyContent: 'end', mb: 1 }}>
                <Button sx={{ mr: 2 }} variant='contained' onClick={() => {
                  setClearQuotationConfirmation(true);
                }}>New Quotation</Button>
                <Button
                  onClick={() => {
                    if (quotationSaving) { return; }

                    if (quotationData == null) {
                      setShowQuotationDataFormDialog(true);
                    } else {
                      handleSaveQuotationData();
                    }

                  }}
                  sx={{ mr: 2 }}
                  variant='contained'>
                  Save Quotation
                </Button>
                <Button variant='contained' onClick={() => {
                  addNewClient();
                }}>Add Client</Button>
              </Box>
              <ClientsInputTable1
                rowData={rowData}
                setRowData={setRowData}
                gridRef={gridRef}
                setCurrency={setCurrency}
                setPaymentFrequency={setPaymentFrequency}
                setAreaOfCoverage={setAreaOfCoverage}
                setCountryOfResidence={setCountryOfResidence}
                currencies={currencies}
                area_of_coverages={area_of_coverages}
                relationships={relationships} />
            </Box>

            <SelectInsurers
              insurers={insurers}
              setInsurers={setInsurers}
              sx={{ mt: 2 }}
              fetchQuotationByKey={fetchQuotationByKey}
              showResult={showResult} />

            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <Button onClick={() => {
                handleViewRatesButtonClicked();
              }}
                sx={{ mt: 2 }}
                variant='contained'
                size='large'
                endIcon={<ArrowForwardIcon />}>View Premium</Button>
            </Box>
          </Box>
        </Box>


        <Box ref={resultRef} sx={{ display: showResult === true ? 'block' : 'none' }}>
          <Button sx={{ mt: 3 }} onClick={() => {
            setShowResult(false);
          }} startIcon={<ArrowBackIcon />}>Edit Clients</Button>
          <Typography variant='h4' sx={{ fontWeight: 700, mt: 1, }}>Results</Typography>
          <SelectInsurers
            insurers={insurers}
            setInsurers={setInsurers}
            sx={{ mt: 2, mb: 2 }}
            fetchQuotationByKey={fetchQuotationByKey}
            showResult={showResult} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ display: 'flex', alignItems: 'baseline', ml: 2 }}>
                <Typography variant='body2' fontSize={18} fontWeight={600}>Currency</Typography>
                <CustomSelect2
                  value={currency}
                  onChange={(e) => {
                    setAllLoading(true);
                    setCurrency(e.target.value);

                    setTimeout(() => {
                      resetAllDeductibles();

                      setTimeout(() => {
                        fetchAllQuotations();
                      }, 200);
                    }, 200);
                  }}
                  items={currencies}
                  sx={{ ml: 1, height: 30 }}
                  disabled={isAnyLoading()}
                />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'baseline', ml: 2 }}>
                <Typography variant='body2' fontSize={18} fontWeight={600}>Payment Frequency</Typography>
                <CustomSelect2
                  value={paymentFrequency}
                  onChange={(e) => {
                    setAllLoading(true);
                    setPaymentFrequency(e.target.value);
                    setTimeout(() => {
                      fetchAllQuotations();
                    }, 200);

                  }}
                  items={['Annually', 'Semi Annually', 'Quarterly', 'Monthly']}
                  sx={{ ml: 1, height: 30 }}
                  disabled={isAnyLoading()}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <Button onClick={() => {
                setClearQuotationConfirmation(true);
              }} sx={{ visibility: 'visible', mr: 2 }} variant='contained'>New Quotation</Button>
              <Button
                onClick={() => {
                  if (quotationSaving) { return; }

                  if (quotationData == null) {
                    setShowQuotationDataFormDialog(true);
                  } else {
                    handleSaveQuotationData();
                  }
                }}
                sx={{ mr: 2 }}
                variant='contained'>
                Save Quotation
              </Button>
              <Button onClick={() => {
                // handleDownloadTOB();
                setShowDownload_TOB_FormDialog(true);
              }} sx={{ visibility: 'visible' }} variant='contained' startIcon={<DownloadIcon />}>Download TOB</Button>
            </Box>
          </Box>

          <InsurerTable sx={{ mt: 2, display: lifeline ? 'block' : 'none' }}
            clients={rowData}
            currency={currency}
            loading={lifelineLoading}
            setLoading={setLifelineLoading}
            selectedPlans={selectedPlans}
            setSelectedPlans={setSelectedPlans}
            assistanceCovers={assistanceCovers}
            setAssistanceCovers={setAssistanceCovers}
            ref={lifelineRef} />

          <BWHO_Rates sx={{ mt: 2, display: bwho ? 'block' : 'none' }}
            clients={rowData}
            currency={currency}
            loading={bwho_Loading}
            setLoading={set_bwho_Loading}
            selectedPlans={selectedPlans}
            setSelectedPlans={setSelectedPlans}
            assistanceCovers={assistanceCovers}
            setAssistanceCovers={setAssistanceCovers}
            ref={bwhoRef} />

          <Company_Rates sx={{ mt: 2, display: company ? 'block' : 'none' }}
            clients={rowData}
            currency={currency}
            loading={companyLoading}
            setLoading={setCompanyLoading}
            selectedPlans={selectedPlans}
            setSelectedPlans={setSelectedPlans}
            assistanceCovers={assistanceCovers}
            setAssistanceCovers={setAssistanceCovers}
            ref={companyRef} />

          <OilGas_Rates sx={{ mt: 2, display: oilGas ? 'block' : 'none' }}
            clients={rowData}
            currency={currency}
            loading={oilGasLoading}
            setLoading={setOilGasLoading}
            selectedPlans={selectedPlans}
            setSelectedPlans={setSelectedPlans}
            assistanceCovers={assistanceCovers}
            setAssistanceCovers={setAssistanceCovers}
            ref={oilGasRef} />

        </Box>
        {/* <Box sx={{ display: 'block', width: '95vw' }}>
          <ColumnGroupingTable />
        </Box> */}
        <Box sx={{ mt: 5, width: '10px', height: '10px' }}></Box>
      </Main>

      {/* Dialogs */}
      <AlertDialog title='Start New Quotation' content='This will clear the form and start a new quotation'
        positiveButtonLabel='Confirm' negativeButtonLabel='Cancel' open={clearQuotationConfirmation} setOpen={setClearQuotationConfirmation}
        callback={() => {
          setClearQuotationConfirmation(false);
          handleNewQuotation();
        }} />

      {/* <QuotationDataFormDialog open={showQuotationDataFormDialog} setOpen={setShowQuotationDataFormDialog} /> */}

      <AlertDialog title='Save Quotation Data' content='Do you want to save this data?'
        positiveButtonLabel='Yes' negativeButtonLabel='Cancel' open={showQuotationDataFormDialog} setOpen={setShowQuotationDataFormDialog}
        callback={() => {
          setShowQuotationDataFormDialog(false);
          handleSaveQuotationData();
        }} />

      <Download_TOB_FormDialog
        open={showDownload_TOB_FormDialog}
        setOpen={setShowDownload_TOB_FormDialog}
        selectedPlans={filterSelectedInsurerPlan()}
        handleDownloadTOB={handleDownloadTOB}
        setRecommendedPlans={setRecommendedPlans}
        recommendedPlans={recommendedPlans}
        tobNotes={tobNotes}
        setTobNotes={setTobNotes} />
    </Box>
  )
}

export default Quotation