import { colors, Skeleton, styled, TableCell, TableRow } from "@mui/material";
import { tableCellClasses } from '@mui/material/TableCell';
import { tableRowClasses } from '@mui/material/TableRow';
import { useEffect, useRef, useState } from "react";


export default function TableRatesRow({ planName, planLabel, clients, bgColor, loading, rates, currency, visible = true, additionalColumns = [], planLabelColSpan = 1, planLabelFontSize = null, discount = 0 }) {
  const tableRowRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState(0);

  useEffect(() => {
    const parentTable = tableRowRef.current.closest('table');

    if (parentTable) {
      // Select all TableRow elements inside the TableBody
      const rows = document.querySelectorAll('#' + parentTable.id + ' .MuiTableBody-root .MuiTableRow-root');

      // Calculate the maximum height
      let max = 0;
      rows.forEach(row => {
        const height = row.offsetHeight;
        if (height > max) {
          max = height;
        }
      });

      setMaxHeight(max);
    }
  }, [rates]);

  function generateKey(client, index) {
    if (client == null) { return; }

    const name = client.name || '';
    const age = String(client.age || '');
    const idx = String(index || '');
    return name + age + idx;
  }

  function formatRate(rate) {
    if (isNaN(rate) || rate == 'Unavailable' || typeof rate === 'string') {
      return <span style={{ color: '#f44336' }}>{'Unavailable'}</span>
    }

    if (isNaN(rate)) {
      return rate;
    }

    return getCurrencySign() + rate.toLocaleString('en-PH', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  function getCurrencySign() {
    if (currency === 'USD') {
      return '$';
    } else if (currency === 'EURO') {
      return '€';
    } else if (currency === 'GBP') {
      return '£';
    } else if (currency === 'SGD') {
      return 'SGD';
    }

    return '';
  }

  function computeRatesSubtotal(field) {
    if (rates == null) { return; }

    let total = 0;

    for (let i = 0; i < rates.length; i++) {
      if (isNaN(rates[i][field])) {
        return 0;
      }

      total += rates[i][field];
    }

    const totalPercent = (100 - discount) * 0.01;

    return total * totalPercent;
  }

  function computedRate(clientRate, planName) {
    const totalPercent = (100 - discount) * 0.01;

    return clientRate[planName] * totalPercent;
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      border: '2px solid #fff',
      backgroundColor: colors.grey[100],
      height: maxHeight
    },
  }));




  return (
    <TableRow ref={tableRowRef} sx={{ display: visible === true ? 'table-row' : 'none' }}>

      <StyledTableCell sx={{ background: bgColor, fontSize: planLabelFontSize }} colSpan={planLabelColSpan}>{planLabel}</StyledTableCell>

      {
        loading ? (additionalColumns.map((additionalColumn, index) => {
          return <StyledTableCell key={additionalColumn.value + index}><Skeleton variant="rounded" width={100} height={20} /></StyledTableCell>
        })) :
          (additionalColumns.map((additionalColumn, index) => {
            return <StyledTableCell key={additionalColumn.value + index} sx={{ background: additionalColumn.bgColor ? additionalColumn.bgColor : bgColor, textAlign: additionalColumn.textAlign }}>{additionalColumn.value}</StyledTableCell>
          }))
      }

      {
        loading ?
          (
            clients.map((client, index) => {
              return <StyledTableCell key={client.uid}><Skeleton variant="rounded" width={100} height={20} /></StyledTableCell>
            })) :
          (rates.map((client_rate, index) => {
            return <StyledTableCell key={client_rate.uid}>{formatRate(computedRate(client_rate, planName))}</StyledTableCell>
          }))
      }
      <StyledTableCell>{loading ? <Skeleton variant="rounded" width={100} height={20} /> :
        formatRate(computeRatesSubtotal(planName))}</StyledTableCell>
    </TableRow>
  );
}