import ApiClient from "./ApiClient";

export const uploadFile = ({ body, token }) => {
  return ApiClient.post('/api/insurer_rates/upload_rates', body, {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })
}


export const fetchInsurerRates = ({  token }) => {
  return ApiClient.get('/api/insurer_rates', {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })
}

export const downloadFile = ({ id, token }) => {
  return ApiClient.get('/api/insurer_rates/download_rates?id=' + id, {
    headers: {
      'Authorization': 'Bearer ' + token
    },
    responseType: 'blob'
  })
}