import ApiClient from "./ApiClient";

export const fetchAllQuotationData = ({ token, search }) => {
  return ApiClient.get('/api/quotation_data?search=' + search, {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })
}

export const fetchQuotationData = ({ quotationId, token }) => {
  return ApiClient.get('/api/quotation_data/' + quotationId, {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })
}

export const addQuotationData = ({ body, token }) => {
  return ApiClient.post('/api/quotation_data', body, {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })
}

export const updateQuotationData = ({ quotationId, body, token }) => {
  return ApiClient.put('/api/quotation_data/' + quotationId, body, {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })
}

export const deleteQuotationData = ({ quotationId, token }) => {
  return ApiClient.delete('/api/quotation_data/' + quotationId, {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })
}