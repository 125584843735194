import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useContext, useState } from 'react';
import { Button, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { AuthContext } from '../utils/AuthContext';
// import { updatePassword } from '../services/UsersService';
import { toast } from 'react-toastify';
import { updatePassword } from '../services/UserService';

// TODO remove, this demo shouldn't need to reset the theme.


function ChangePassword() {
  const { auth, setAuth } = useContext(AuthContext);



  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const navigate = useNavigate();


  function handleSubmit(event) {
    event.preventDefault();
    const isValid = validateForm();

    if (isValid) {
      handleUpdatePassword();
    }
  }

  function validateForm() {
    let isValid = true;

    setCurrentPasswordError('');
    setNewPasswordError('');
    setConfirmPasswordError('');


    if (currentPassword.trim() === '') {
      setCurrentPasswordError('Current Password is Required');
      isValid = false;
    }

    if (newPassword.trim() === '') {
      setNewPasswordError('New Password is Required');
      isValid = false;
    } else if (newPassword.trim().length < 6) {
      setNewPasswordError('New Password is too short');
      isValid = false;
    }

    if (confirmPassword.trim() === '') {
      setConfirmPasswordError('Confirm Password is Required');
      isValid = false;
    } else if (newPassword !== confirmPassword) {
      setConfirmPasswordError('New Password and Confirm Password is not same');
      isValid = false;
    }

    return isValid;
  }

  async function handleUpdatePassword() {
    try {
      const response = await updatePassword({ token: auth.token, userId: auth.user.id, newPassword, currentPassword  });

      if (response.status == 200) {
        toast.success("Password Updated", {
          autoClose: 1000,
          hideProgressBar: true,
        });

        handleLogout();
      }

    } catch(err) {
      if (err.response.status == 422) {
        toast.error(err.response.data.message, {
          autoClose: 1000,
          hideProgressBar: true,
        });
      }

    }
  }

  function handleLogout() {
    localStorage.removeItem('quoting-api-token');
    localStorage.removeItem('quoting-current-user');

    setAuth({
      token: null,
      user: null
    });

    navigate('/login');
  }

  return (
    <Box sx={{ display: 'flex' }}>
      {/* <CssBaseline /> */}
      {/* <MyAppBar open={open} toggleDrawer={toggleDrawer} />
      <MyDrawer open={open} toggleDrawer={toggleDrawer} /> */}
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Paper sx={{ mt: 8, mx: 3, width: '400px', mx: 'auto', p:4 }}>
          <Typography variant="h4" align="center">
            Change Password
          </Typography>

          <Box component="form" method="POST" noValidate onSubmit={handleSubmit} sx={{ mt: 3, display: 'flex', flexDirection: 'column' }}>
            <TextField
              sx={{ mt: 2 }}
              required
              fullWidth
              id="currentPassword"
              label="Current Password"
              name="currentPassword"
              type="password"
              value={currentPassword}
              onChange={(e) => { setCurrentPassword(e.target.value) }}
              error={currentPasswordError !== ''}
              helperText={currentPasswordError}
            />
            <TextField
              sx={{ mt: 2 }}
              required
              fullWidth
              id="newPassword"
              label="New Password"
              name="newPassword"
              type="password"
              value={newPassword}
              onChange={(e) => { setNewPassword(e.target.value) }}
              error={newPasswordError !== ''}
              helperText={newPasswordError}
            />
            <TextField
              sx={{ mt: 2 }}
              required
              fullWidth
              id="confirmPassword"
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              value={confirmPassword}
              onChange={(e) => { setConfirmPassword(e.target.value) }}
              error={confirmPasswordError !== ''}
              helperText={confirmPasswordError}
            />


            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3 }}
              size="large"
            >
              Update
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

export default ChangePassword;
