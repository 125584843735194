import React, { useState } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useEffect } from 'react';
import { useContext } from 'react'

import { toast, ToastContainer } from 'react-toastify';
import { NavLink, useNavigate } from 'react-router-dom';
import { FormControl, InputLabel, MenuItem, Paper, Select } from '@mui/material';


import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { AuthContext } from '../utils/AuthContext';
import { register } from '../services/AuthController';
import Global from '../utils/Global';


export default function Register() {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [email, setEmail] = useState('');
  const [nickName, setNickName] = useState('');
  const [roleId, setRoleId] = useState(2);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [middleNameError, setMiddleNameError] = useState('');
  const [nickNameError, setNickNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [roleIdError, setRoleIdError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordConfirmationError, setPasswordConfirmationError] = useState('');

  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (auth.user.role_id === Global.ROLE_ADMIN || auth.user.role_id === Global.ROLE_SUPER_ADMIN) {
      // User is authorized, do nothing
    } else {
      // User is not authorized, redirect
      navigate('/quotation');
    }
  }, []);


  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [showPassword2, setShowPassword2] = React.useState(false);

  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  const { auth, setAuth } = useContext(AuthContext);

  function validate() {

    setFirstNameError('');
    setLastNameError('');
    setMiddleNameError('');
    setEmailError('');
    setNickNameError('');
    setPasswordConfirmationError('');
    setPasswordError('');
    // setRoleIdError('')

    let isValid = true;
    if (firstName.trim() === '') {
      setFirstNameError('First Name is Required');
      isValid = false
    }
    if (lastName.trim() === '') {
      setLastNameError('Last Name is Required');
      isValid = false;
    }
    if (nickName.trim() === '') {
      setNickNameError('Nickname is Required');
      isValid = false;
    }
    if (email.trim() === '') {
      setEmailError('Email is Required');
      isValid = false;
    }
    if (password.trim() === '') {
      setPasswordError('Password is Required');
      isValid = false;
    }
    if (passwordConfirmation.trim() === '') {
      setPasswordConfirmationError('Password Confirmation is Required');
      isValid = false;
    }

    // if (roleId.trim() === '') {
    //   setRoleIdError('Role is Required')
    //   isValid = false
    // }

    return isValid;
  }

  function handleSignUpClick(event) {
    event.preventDefault();

    let isValid = validate();

    if (!isValid) {
      return
    }

    setLoading(true);

    const user = {
      'given_name': firstName,
      'last_name': lastName,
      'middle_name': middleName,
      'nick_name': nickName,
      'password': password,
      'email': email,
      'role_id': roleId
    }

    register({ user, token: auth.token }).then(res => {
      toast.success('User Created', {
        autoClose: 2000,
        hideProgressBar: true,
      });

      setLoading(false);
      clearForm();
    }).catch(error => {
      toast.error(error.response.data.message, {
        autoClose: 2000,
        hideProgressBar: true,
      });

      setLoading(false);
    }

    )
  }

  function clearForm() {
    setFirstName('');
    setLastName('');
    setMiddleName('');
    setNickName('');
    setEmail('');
    setPassword('');
    setPasswordConfirmation('');
  }

  return (
    <Box sx={{
      display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', py: 8, backgroundColor: (theme) =>
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[900],
    }}>
      <Paper elevation={2} sx={{ p: 4, mt: 5, mb: 5, width: 400 }}>
        <ToastContainer />
        <Typography align="center">
          {/* <img src="/images/logo.png" alt="" id="logo" /> */}
        </Typography>
        <Typography sx={{ mt: 3 }} component="h1" variant="h4" align="center">
          Create Account
        </Typography>

        <Box onSubmit={handleSignUpClick} component="form" method="POST" noValidate sx={{ mt: 3 }}>
          {/* <Box sx={{ display: 'flex' }}> */}
          <TextField
            name="First Name"
            required
            fullWidth
            id="first_name"
            label="First Name"
            autoFocus
            autoComplete="first_name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            error={firstNameError !== ''}
            helperText={firstNameError}
          />

          <TextField
            sx={{ mt: 2 }}
            name="Last Name"
            required
            fullWidth
            id="last_name"
            label="Last Name"
            autoComplete="last_name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            error={lastNameError !== ''}
            helperText={lastNameError}
          />
          <TextField
            sx={{ mt: 2 }}
            name="Middle Name"
            fullWidth
            id="middle_name"
            label="Middle Name"
            autoComplete="middle_name"
            value={middleName}
            onChange={(e) => setMiddleName(e.target.value)}
            error={middleNameError !== ''}
            helperText={middleNameError}
          />
          {/* </Box> */}
          <TextField
            sx={{ mt: 2 }}
            name="Nickname"
            required
            fullWidth
            id="nickname"
            value={nickName}
            onChange={(e) => setNickName(e.target.value)}
            label="Nickname"
            autoComplete="nickname"
            error={nickNameError !== ''}
            helperText={nickNameError}
          />
          <FormControl sx={{ mt: 2 }} fullWidth>
            <InputLabel id="role-select">Role</InputLabel>
            <Select
              labelId="role-select"
              value={roleId}
              label="Role"
              onChange={(e) => { setRoleId(e.target.value) }}
            >
              <MenuItem value={2}>User</MenuItem>
              <MenuItem value={1}>Admin</MenuItem>
            </Select>
          </FormControl>

          <TextField
            sx={{ mt: 2 }}
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={emailError !== ''}
            helperText={emailError}
          />

          {/* <FormControl fullWidth sx={{ mt: 1 }}>
            <InputLabel id="demo-simple-select-label">Role</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Role"
              onChange={(event) => {
                setRoleId(event.target.value)
              }}
              value={roleId}
              error={roleIdError !== ''}
              helperText={roleIdError}
            >
              <MenuItem value={'1'}>User</MenuItem>
              <MenuItem value='2'>Accounting</MenuItem>
              <MenuItem value='3'>Manager</MenuItem>
              <MenuItem value='4'>IT Admin</MenuItem>

            </Select>
          </FormControl> */}

          {/* <TextField
            sx={{ mt: 2 }}
            required
            fullWidth
            id="password"
            label="Password"
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={passwordError !== ''}
            helperText={passwordError}
          />
          <TextField
            sx={{ mt: 2 }}
            required
            fullWidth
            id="password_confirmation"
            label="Confirm Password"
            name="password_confirmation"
            type="password"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            error={passwordConfirmationError !== ''}
            helperText={passwordConfirmationError}
          /> */}

          <FormControl sx={{ mt: 2, width: '100%' }} variant="outlined">
            <InputLabel htmlFor="password">Password</InputLabel>
            <OutlinedInput
              id="password"
              type={showPassword ? 'text' : 'password'}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              error={passwordError !== ''}
              helperText={passwordError}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>

          <FormControl sx={{ mt: 2, width: '100%' }} variant="outlined">
            <InputLabel htmlFor="confirm-password">Confirm Password</InputLabel>
            <OutlinedInput
              id="confirm-password"
              type={showPassword2 ? 'text' : 'password'}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              value={passwordConfirmation}
              error={passwordConfirmationError !== ''}
              helperText={passwordConfirmationError}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword2}
                    onMouseDown={handleMouseDownPassword2}
                    edge="end"
                  >
                    {showPassword2 ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm Password"
            />
          </FormControl>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3 }}
            size="large"
            disabled={isLoading}
          >
            Submit
          </Button>
        </Box>
      </Paper >
    </Box >
  );
}