import { Avatar, Button, MenuItem, Paper, Select, TextField, Typography } from "@mui/material"
import { Box, Container } from "@mui/system"
import { useContext, useEffect, useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from "../utils/AuthContext";
import { useRef } from "react";
import { updateProfile } from "../services/UserService";
import global from './../utils/Global'

function Profile() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [nickname, setNickname] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [title, setTitle] = useState('');
  const [image, setImage] = useState();
  const inputFileRef = useRef();

  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [nicknameError, setNicknameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [mobileNumberErr, setMobileNumberErr] = useState('');
  const [titleErr, setTitleErr] = useState('');


  const { auth, setAuth } = useContext(AuthContext);

  const [isUpdating, setUpdating] = useState(false);


  useEffect(() => {
    setFirstName(auth.user.given_name);
    setLastName(auth.user.last_name);
    setNickname(auth.user.nick_name);
    setEmail(auth.user.email);
    setMobileNumber(auth.user.mobile_number);
    setTitle(auth.user.title);
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    const isValid = validateForm();

    if (isValid) {
      handleUpdateUser();
    }
  }

  function validateForm() {
    let isValid = true;

    setFirstNameError('');
    setLastNameError('');
    setNicknameError('');
    setEmailError('');
    setMobileNumberErr('');
    setTitleErr('');


    if (firstName.trim() === '') {
      setFirstNameError('First Name is Required');
      isValid = false;
    }

    if (lastName.trim() === '') {
      setLastNameError('Last Name is Required');
      isValid = false;
    }

    if (nickname.trim() === '') {
      setNicknameError('Nickname is Required');
      isValid = false;
    }

    if (email.trim() === '') {
      setEmailError('Email is Required');
      isValid = false;
    }

    if (mobileNumber.trim() === '') {
      setEmailError('Mobile Number is Required');
      isValid = false;
    }

    if (title.trim() === '') {
      setTitle('Title is Required');
      isValid = false;
    }

    return isValid;
  }

  async function handleUpdateUser() {

    const user = new FormData();
    user.append('given_name', firstName);
    user.append('last_name', lastName);
    user.append('nick_name', nickname);
    user.append('mobile_number', mobileNumber);
    user.append('title', title);

    if (image) {
      user.append('image', image);
    }

    setUpdating(true);

    updateProfile({ token: auth.token, userId: auth.user.id, body: user })
      .then((res) => {
        const user = res.data.user;

        setAuth({
          user: user,
          token: auth.token
        });

        localStorage.setItem('quoting-current-user', JSON.stringify(user));

        inputFileRef.current.value = '';

        toast.success('Profile Updated', {
          autoClose: 1000,
          hideProgressBar: true,
        });

        setUpdating(false);

        window.location.reload(false);
      })
      .catch(err => {
        setUpdating(false);
      })
  }

  return (
    <>
      <Container sx={{ mt: 5, width: '500px' }}>
        <Paper elevation={2} sx={{ px: 5, py: 7 }}>
          <Typography variant="h4" align="center">
            Profile
          </Typography>


          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Avatar alt={auth.user.given_name + ' Image'} src={global.storageURL + '/storage/uploads/' + auth.user.image} sx={{ width: 80, height: 80 }} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
            <input
              type="file"
              ref={inputFileRef}
              onChange={(e) => {
                setImage(e.target.files[0]);
              }}
              accept="images/*"
            />
          </Box>



          <Box component="form" method="POST" noValidate onSubmit={handleSubmit} sx={{ mt: 3, display: 'flex', flexDirection: 'column' }}>

            <Box sx={{ display: 'flex' }}>
              <TextField
                name="First Name"
                required
                fullWidth
                id="first_name"
                label="First Name"
                autoFocus
                autoComplete="first_name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                error={firstNameError !== ''}
                helperText={firstNameError}
                sx={{ mr: 1 }}
              />

              <TextField
                name="Last Name"
                required
                fullWidth
                id="last_name"
                label="Last Name"
                autoComplete="last_name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                error={lastNameError !== ''}
                helperText={lastNameError}
                sx={{ ml: 1 }}
              />
            </Box>

            <TextField
              sx={{ mt: 2 }}
              name="Nickname"
              required
              fullWidth
              id="nickname"
              label="Nickname"
              autoComplete="nickname"
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
              error={nicknameError !== ''}
              helperText={nicknameError}
            />

            <TextField
              sx={{ mt: 2 }}
              name="Email"
              required
              fullWidth
              id="email"
              label="Email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={emailError !== ''}
              helperText={emailError}
              disabled
            />

            <TextField
              sx={{ mt: 2 }}
              name="Mobile Number"
              required
              fullWidth
              id="email"
              label="Mobile Number"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              error={mobileNumberErr !== ''}
              helperText={mobileNumberErr}
            />

            <TextField
              sx={{ mt: 2 }}
              name="Title"
              required
              fullWidth
              id="email"
              label="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              error={titleErr !== ''}
              helperText={titleErr}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3 }}
              size="large"
              disabled={isUpdating}
            >
              Update
            </Button>
          </Box>
        </Paper>
      </Container>
    </>
  )
}

export default Profile