import './App.css';
import { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthContext } from './utils/AuthContext';
import PrivateRoutes from './utils/PrivateRoutes'
import Login from './pages/Login';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Quotation from './pages/Quotation';
import QuotationList from './pages/QuotationList';
import Register from './pages/Register';
import InsurerRates from './pages/InsurerRates';
import Profile from './pages/Profile';
import AppFiles from './pages/AppFiles';
import ChangePassword from './pages/ChangePassword';
import { ConfigContext } from './utils/ConfigContext';
import Quotation1 from './pages/Quotation1';

function App() {

  const token = localStorage.getItem('quoting-api-token');
  const user = JSON.parse(localStorage.getItem('quoting-current-user'));

  const content = JSON.parse(localStorage.getItem('quoting-app-config'));
  const configTimestamp = localStorage.getItem('quoting-app-config-timestamp');

  const [auth, setAuth] = useState({
    token,
    user
  });

  const [appConfig, setAppConfig] = useState({
    content,
    configTimestamp
  });

  const subDir = 'quoting';

  return (
    <>
      <ConfigContext.Provider value={{ appConfig, setAppConfig }}>
        <AuthContext.Provider value={{ auth, setAuth }}>
          <BrowserRouter basename={subDir}>
            <Routes>
              <Route element={<Login />} path='/login' />
              <Route element={<Login />} path='/' />
              <Route element={<PrivateRoutes />}>
                <Route element={<Register />} path='/create_account' />
                <Route element={<Quotation1 />} path='/quotation' />
                <Route element={<QuotationList />} path='/quotation_list' />
                <Route element={<InsurerRates />} path='/insurer_rates' />
                <Route element={<AppFiles />} path='/app_files' />
                <Route element={<Profile />} path='/profile' />
                <Route element={<ChangePassword />} path='/change_password' />
              </Route>
            </Routes>
          </BrowserRouter>
        </AuthContext.Provider>
      </ConfigContext.Provider>
      <ToastContainer />
    </>
  );
}

export default App;
