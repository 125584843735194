import ApiClient from "./ApiClient";

export const getQuotation = ({ body, token }) => {
  return ApiClient.post('/api/quotations/lifeline/get_quotation', body, {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })
}

export const getRates = ({ body, token }) => {
  return ApiClient.post('/api/quotations/get_rates', body, {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })
}


export const downloadTOB = ({ body, token }) => {
  return ApiClient.post('/api/quotations/tob/download', body, {
    headers: {
      'Authorization': 'Bearer ' + token
    },
    responseType: 'blob',
    withCredentials: false,
    
  })
}

export const fetchTOB_Data = ({ body, token }) => {
  return ApiClient.post('/api/quotations/tob', body, {
    headers: {
      'Authorization': 'Bearer ' + token
    },
    withCredentials: false,
    
  })
}

