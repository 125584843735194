import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { forwardRef, useContext, useEffect, useState } from 'react';
import CustomSelect2 from './CustomSelect2';
import { Box, Typography, FormControlLabel, Grid, TextField, InputAdornment, Select, MenuItem, colors, Button } from '@mui/material';
import SelectPlanCheckbox from './SelectPlanCheckbox';
import { ConfigContext } from '../utils/ConfigContext';
import TableRatesRow from './TableRatesRow';
import getSafe from '../utils/getSafe';
import domtoimage from 'dom-to-image';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


const IHHP_Rates = forwardRef(({ sx, clients, loadingStatus, currency, selectedPlans, setSelectedPlans,
  response, deductibles, setDeductibles, addToPendingTasks,
  discounts, setDiscounts, computedRates, setComputedRates,
  areaOfCoverage, specificAreaOfCoverage, setSpecificAreaOfCoverage,
  setShowImageCopy }, ref) => {
  const { appConfig } = useContext(ConfigContext);

  const id = 'ihhp';

  const plans = ['ihhp_hospital_plan', 'ihhp_non_hospitalisation_benefits', 'ihhp_medicine_appliances', 'ihhp_medical_evacuation_repatriation', 'ihhp_dental_optical_a', 'ihhp_dental_optical_b'];

  const loadings = getSafe(() => appConfig.content.specifics.ihhp.loadings);
  const specific_area_of_coverages = getSafe(() => appConfig.content.ihhp_area_of_coverage);

  useEffect(() => {
    computedRates[id] = getComputedRates();
    setComputedRates(computedRates);
  }, [response, discounts]);

  function getComputedRates() {
    if (!response) return;

    const rates = response.rates;
    const discount = discounts[id];
    const totalPercent = (100 - discount) * 0.01;

    const computedRates = [];

    for (let i = 0; i < rates.length; i++) {
      const hospital_plan = computedRateWithLoadings(rates[i].hospital_plan, rates[i].client.age);
      const non_hospitalisation_benefits = computedRateWithLoadings(rates[i].hospital_plan + rates[i].non_hospitalisation_benefits, rates[i].client.age);
      const medicine_appliances = computedRateWithLoadings(rates[i].hospital_plan + rates[i].non_hospitalisation_benefits + rates[i].medicine_appliances, rates[i].client.age);
      const dental_optical_a = computedRateWithLoadings(rates[i].hospital_plan + rates[i].non_hospitalisation_benefits + rates[i].medicine_appliances + rates[i].dental_optical_a, rates[i].client.age);
      const dental_optical_b = computedRateWithLoadings(rates[i].hospital_plan + rates[i].non_hospitalisation_benefits + rates[i].medicine_appliances + rates[i].dental_optical_b, rates[i].client.age);

      computedRates.push({
        'client': rates[i].client,
        'hospital_plan': hospital_plan * totalPercent,
        'non_hospitalisation_benefits': non_hospitalisation_benefits * totalPercent,
        'medicine_appliances': medicine_appliances * totalPercent,
        'dental_optical_a': dental_optical_a * totalPercent,
        'dental_optical_b': dental_optical_b * totalPercent,
      })
    }

    return computedRates;
  }


  function checkIfThereIsAnySelectedPlan() {
    for (let i = 0; i < selectedPlans.length; i++) {
      if (plans.includes(selectedPlans[i])) {
        return true;
      }
    }

    return false;
  }

  function computeIHHP_Rates(rates) {
    const computedRates = [];

    for (let i = 0; i < rates.length; i++) {
      computedRates.push({
        'client': rates[i].client,
        'hospital_plan': computedRateWithLoadings(rates[i].hospital_plan, rates[i].client.age),
        'non_hospitalisation_benefits': computedRateWithLoadings(rates[i].non_hospitalisation_benefits, rates[i].client.age),
        'medicine_appliances': computedRateWithLoadings(rates[i].medicine_appliances, rates[i].client.age),
        'medical_evacuation_repatriation': computedRate(rates[i].medical_evacuation_repatriation),
        'dental_optical_a': computedRate(rates[i].dental_optical_a),
        'dental_optical_b': computedRate(rates[i].dental_optical_b)
      })
    }

    return computedRates;
  }

  function computedRateWithLoadings(rate, age) {
    if (rate === 'Unavailable' || typeof rate === 'string') {
      return rate;
    }

    const loadingsVal = getLoadings(age);

    return rate + (rate * loadingsVal);
  }

  function computedRate(rate) {
    if (rate === 'Unavailable' || typeof rate === 'string') {
      return rate;
    }

    return rate;
  }

  function onInputDiscountChange(e) {
    var numericValue = e.target.value.replace(/[^0-9]/g, '');

    // Remove leading zeros
    numericValue = numericValue.replace(/^0+/, '');

    if (numericValue > 100) {
      numericValue = 100;
    }

    discounts[id] = numericValue;

    setDiscounts({ ...discounts });
  }

  function getEquivalentAreaOfCoverage() {
    let _areaOfCoverage = areaOfCoverage;

    if (specificAreaOfCoverage[id] === 'parent') {
      _areaOfCoverage = areaOfCoverage;
    } else {
      _areaOfCoverage = specificAreaOfCoverage[id];
    }

    if (_areaOfCoverage === 'Worldwide' || _areaOfCoverage === 'Worldwide Plus' || _areaOfCoverage === 'HONG KONG & U.S') {
      return 'HONG KONG & U.S';
    }

    return 'REST OF THE WORLD (Excl. Hong Kong & U.S. & European Economic Area)';
  }

  function getLoadings(age) {
    for (let range in loadings) {
      let [min, max] = range.split('-').map(Number);
      if (age >= min && age <= max) {
        return loadings[range];
      }
    }
    return 0;
  }

  function displayLoadings(age) {
    const val = getLoadings(age);

    if (val === 0) return '';

    return '(' + (val * 100) + '% loadings)'
  }

  function titleCasePhrase(str, phraseToTitleCase) {
    const regex = new RegExp(phraseToTitleCase, 'gi');
    return str.replace(regex, match => {
      return match
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    });
  }

  return (
    <Box sx={sx}>
      <Paper sx={{ borderColor: '#e0e0e0', borderWidth: 2, pb: 2 }} variant='outlined' >
        <Typography variant='h5' sx={{ color: '#fff', background: '#ffa000', px: 2, py: 1 }}>IHHP</Typography>

        <Box sx={{ p: 2 }}>
          {/* <Grid container alignItems='center'>
            <Grid item xs={1.5}>
              <Typography fontSize={18} fontWeight={600}>Select Plans</Typography>
            </Grid>
            <Grid item xs={1.3}>
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='ihhp_hospital_plan' />} label="Hospital Plan" />
            </Grid>
            <Grid item xs={1.3}>
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='ihhp_non_hospitalisation_benefits' />} label="Module 1" />
            </Grid>
            <Grid item xs={1.3}>
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='ihhp_medicine_appliances' />} label="Module 2" />
            </Grid>
            <Grid item xs={1.3}>
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='ihhp_medical_evacuation_repatriation' />} label="Module 3" />
            </Grid>

            <Grid item xs={1.3}>
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='ihhp_dental_optical_a' />} label="Module 4A" />
            </Grid>
            <Grid item xs={1.3}>
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='ihhp_dental_optical_b' />} label="Module 4B" />
            </Grid>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={1.5}>
              <Typography fontSize={18} fontWeight={600}>Deductible</Typography>
            </Grid>
            <Grid item xs={1.8}>
              <CustomSelect2
                value={deductibles[id]}
                onChange={(e) => {
                  // setDeductible(e.target.value);

                  // setDeductibleIndex(getDeductibleIndex(e.target.value));

                  const val = e.target.value;

                  const _deductibles = JSON.parse(JSON.stringify(deductibles));

                  _deductibles[id] = val;

                  setDeductibles(_deductibles);

                  addToPendingTasks([id]);

                }}
                items={getSafe(() => appConfig.content.deductibles.ihhp[currency], [])}
                disabled={loadingStatus[id]}
              />
            </Grid>


            <Grid item xs={12}></Grid>
            <Grid item xs={1.5} mt={1}><Typography fontSize={18} fontWeight={600}>Renewal/Further Discount</Typography></Grid>
            <Grid item xs={1} mt={1}>
              <TextField
                size='small'
                sx={{ width: '80px', textAlign: 'end' }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                value={discounts[id]}
                onChange={onInputDiscountChange}
              />
            </Grid>
            <Grid item xs={1.5}><Typography fontSize={18} fontWeight={600}>Area Of Coverage</Typography></Grid>
            <Grid item xs={2}>
              <Select
                size='small'
                value={specificAreaOfCoverage[id]}
                onChange={(e) => {
                  specificAreaOfCoverage[id] = e.target.value;
                  setSpecificAreaOfCoverage({ ...specificAreaOfCoverage });

                  addToPendingTasks([id]);
                }}
                sx={{ fontSize: 12, height: 40, width: '190px' }}
                fullWidth
                disabled={loadingStatus[id]}
              >
                <MenuItem value={'parent'} sx={{ color: colors.blue[500] }}>{areaOfCoverage}</MenuItem>
                {
                  specific_area_of_coverages && specific_area_of_coverages.map(area_of_coverage => (
                    <MenuItem sx={{ display: areaOfCoverage === area_of_coverage ? 'none' : 'block' }} key={area_of_coverage} value={area_of_coverage}>{area_of_coverage}</MenuItem>
                  ))
                }

              </Select>
            </Grid>
            <Grid item xs={7} mt={1}>
              <Typography fontSize={18}>
                Area of Cover: <span display='inline-block' style={{ fontSize: 18, color: '#002047', fontWeight: 600 }}>{getEquivalentAreaOfCoverage()}</span>
              </Typography>
            </Grid>
          </Grid> */}

          <Box sx={{ display: 'flex', gap: 5 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography fontSize={18} fontWeight={600}>Plan Type</Typography>
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='ihhp_hospital_plan' />} label="Hospital Plan" />
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='ihhp_non_hospitalisation_benefits' />} label="Module 1" />
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='ihhp_medicine_appliances' />} label="Module 2" />
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='ihhp_medical_evacuation_repatriation' />} label="Module 3" />
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='ihhp_dental_optical_a' />} label="Module 4A" />
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='ihhp_dental_optical_b' />} label="Module 4B" />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 450 }}>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Deductible Option</Typography>
                <CustomSelect2
                  sx={{ minWidth: 190 }}
                  value={deductibles[id]}
                  onChange={(e) => {
                    // setDeductible(e.target.value);

                    // setDeductibleIndex(getDeductibleIndex(e.target.value));

                    const val = e.target.value;

                    const _deductibles = JSON.parse(JSON.stringify(deductibles));

                    _deductibles[id] = val;

                    setDeductibles(_deductibles);

                    addToPendingTasks([id]);

                  }}
                  items={getSafe(() => appConfig.content.deductibles.ihhp[currency], [])}
                  disabled={loadingStatus[id]}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Area Of Coverage</Typography>
                <Select
                  size='small'
                  value={specificAreaOfCoverage[id]}
                  onChange={(e) => {
                    specificAreaOfCoverage[id] = e.target.value;
                    setSpecificAreaOfCoverage({ ...specificAreaOfCoverage });

                    addToPendingTasks([id]);
                  }}
                  sx={{ fontSize: 12, height: 40, width: '190px', minWidth: 190 }}
                  fullWidth
                  disabled={loadingStatus[id]}
                >
                  <MenuItem value={'parent'} sx={{ color: colors.blue[500] }}>{areaOfCoverage}</MenuItem>
                  {
                    specific_area_of_coverages && specific_area_of_coverages.map(area_of_coverage => (
                      <MenuItem sx={{ display: areaOfCoverage === area_of_coverage ? 'none' : 'block' }} key={area_of_coverage} value={area_of_coverage}>{area_of_coverage}</MenuItem>
                    ))
                  }

                </Select>
              </Box>
              <Typography fontSize={18}>
                Area of Cover: <span display='inline-block' style={{ fontSize: 18, color: '#002047', fontWeight: 600 }}>{titleCasePhrase(getEquivalentAreaOfCoverage(), "REST OF THE WORLD")}</span>
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Renewal/Further Discount</Typography>
                <TextField
                  size='small'
                  sx={{ width: '80px', textAlign: 'end' }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  value={discounts[id]}
                  onChange={onInputDiscountChange}
                />
              </Box>
            </Box>
          </Box>



          <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
            <Button
              size='small'
              sx={{ mb: 1 }}
              onClick={() => {
                var node = document.getElementById('table-ihhp-rates');

                node.style = 'width: auto;';

                domtoimage.toBlob(node).then(function (blob) {
                  // Create ClipboardItem with blob and its type, and add to an array
                  const data = [new ClipboardItem({ [blob.type]: blob })];
                  // Write the data to the clipboard
                  navigator.clipboard.write(data);

                  setShowImageCopy(true);

                  node.style = 'width: 100%;';
                })
                  .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                    node.style = 'width: 100%;';
                  });
              }}
              endIcon={<ContentCopyIcon />}
            >Copy Image</Button>
          </div>

          <TableContainer component={Paper}>
            <Table id='table-ihhp-rates' size='small' sx={{ minWidth: 650 }}>
              <TableHead sx={{ background: '#ffa000', color: '#fff' }}>
                <TableRow>
                  <TableCell sx={{ width: 260, color: '#fff', fontWeight: 600 }}>Plan</TableCell>
                  {
                    clients.map((client, idx) => {
                      if (client.full_name.trim() === '') {
                        return <TableCell sx={{ width: 200, fontWeight: 600, color: '#fff' }} key={idx}>_</TableCell>
                      }

                      return <TableCell sx={{ width: 200, color: '#fff', fontWeight: 600 }} key={idx}><div>{client.full_name} ({client.age})</div><div>{client.country_of_residence}</div><div>{displayLoadings(client['age'])}</div></TableCell>
                    })
                  }
                  <TableCell sx={{ color: '#fff', fontWeight: 600 }}>Subtotal</TableCell>
                </TableRow>

              </TableHead>
              <TableBody>
                <TableRatesRow
                  planId='ihhp_hospital_plan'
                  planName='hospital_plan'
                  planLabel={<span><span style={{ fontSize: 14 }}>Hospital Plan</span></span>}
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#ffecb3'
                  loading={loadingStatus[id]}
                  rates={response ? computeIHHP_Rates(response.rates) : []}
                  currency={currency}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('ihhp_hospital_plan') || selectedPlans.includes('ihhp_non_hospitalisation_benefits')
                    || selectedPlans.includes('ihhp_medicine_appliances') || selectedPlans.includes('ihhp_dental_optical_a') || selectedPlans.includes('ihhp_dental_optical_b')
                  }
                />
                <TableRatesRow
                  planId='ihhp_non_hospitalisation_benefits'
                  planName='non_hospitalisation_benefits'
                  planLabel={<span><span style={{ fontWeight: 700 }}>Module 1</span> <span style={{ fontSize: 14 }}>(Non-Hospitalisation Benefits)</span></span>}
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#ffecb3'
                  loading={loadingStatus[id]}
                  rates={response ? computeIHHP_Rates(response.rates) : []}
                  currency={currency}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('ihhp_non_hospitalisation_benefits')
                    || selectedPlans.includes('ihhp_medicine_appliances') || selectedPlans.includes('ihhp_dental_optical_a')
                    || selectedPlans.includes('ihhp_dental_optical_b')
                  }
                />
                <TableRatesRow
                  planId='ihhp_medicine_appliances'
                  planName='medicine_appliances'
                  planLabel={<span><span style={{ fontWeight: 700 }}>Module 2</span> <span style={{ fontSize: 14 }}>(Medicine & Appliances)</span></span>}
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#ffecb3'
                  loading={loadingStatus[id]}
                  rates={response ? computeIHHP_Rates(response.rates) : []}
                  currency={currency}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('ihhp_medicine_appliances') || selectedPlans.includes('ihhp_dental_optical_a')
                    || selectedPlans.includes('ihhp_dental_optical_b')}
                />
                <TableRatesRow
                  planId='ihhp_medical_evacuation_repatriation'
                  planName='medical_evacuation_repatriation'
                  planLabel={<span><span style={{ fontWeight: 700 }}>Module 3</span> <span style={{ fontSize: 14 }}>(Medical Evacuation & Repatriation)</span></span>}
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#ffecb3'
                  loading={loadingStatus[id]}
                  rates={response ? computeIHHP_Rates(response.rates) : []}
                  currency={currency}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('ihhp_medical_evacuation_repatriation')}
                />
                <TableRatesRow
                  planId='ihhp_dental_optical_a'
                  planName='dental_optical_a'
                  planLabel={<span><span style={{ fontWeight: 700 }}>Module 4A</span> <span style={{ fontSize: 14 }}>(Dental & Optical)</span></span>}
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#ffecb3'
                  loading={loadingStatus[id]}
                  rates={response ? computeIHHP_Rates(response.rates) : []}
                  currency={currency}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('ihhp_dental_optical_a')}
                />
                <TableRatesRow
                  planId='ihhp_dental_optical_b'
                  planName='dental_optical_b'
                  planLabel={<span><span style={{ fontWeight: 700 }}>Module 4B</span> <span style={{ fontSize: 14 }}>(Dental & Optical)</span></span>}
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#ffecb3'
                  loading={loadingStatus[id]}
                  rates={response ? computeIHHP_Rates(response.rates) : []}
                  currency={currency}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('ihhp_dental_optical_b')}
                />

                {/* {
                  checkIfThereIsAnySelectedPlan() == false &&
                  <TableRow>
                    <TableCell>No Plan Selected</TableCell>
                  </TableRow>
                } */}
              </TableBody>
            </Table>

          </TableContainer>


        </Box>


      </Paper>

    </Box>
  );
});

export default IHHP_Rates;