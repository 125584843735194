import ApiClient from "./ApiClient";

export const updateProfile = ({ body, token, userId }) => {
  return ApiClient.post('/api/users/update_profile/' + userId, body, {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })
}

export const updatePassword = async ({ token, userId, currentPassword, newPassword }) => {
  return await ApiClient.put('/api/users/'+ userId +'/update_password', {
    current_password: currentPassword,
    new_password: newPassword
  }, {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  });
}