import ApiClient from "./ApiClient";

export const login = async ({ email, password }) => {
  return await ApiClient.post('/api/auth/login', {
    email,
    password
  });
}

export const register = ({ user, token }) => {
  return ApiClient.post('/api/auth/register', user, {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })
}