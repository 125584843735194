export default function zeroDeductibleUniform(deductible) {
  const zeroDeductibles = ['Zero Deductible', 'Standard', '0 Deductible', 'Nil'];

  if (zeroDeductibles.indexOf(deductible) !== -1) {
    return 'Nil Deductible';
  }

  // if (!deductible.includes('Deductible')) {
  //   return `${deductible} Deductible`;
  // }

  return deductible;
}