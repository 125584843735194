import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import CustomSelect2 from './CustomSelect2';
import { Box, Typography, FormControlLabel, Checkbox, Grid, TextField, InputAdornment, Select, MenuItem, colors, Button } from '@mui/material';
import SelectPlanCheckbox from './SelectPlanCheckbox';
import { ConfigContext } from '../utils/ConfigContext';
import { AuthContext } from '../utils/AuthContext';
import TableRatesRow from './TableRatesRow';
import { getQuotation } from '../services/CompanyQuotationService';
import getSafe from '../utils/getSafe';
import domtoimage from 'dom-to-image';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


const Company_Rates = forwardRef(({ sx, clients, loadingStatus, currency, selectedPlans, setSelectedPlans,
  response, deductibles, setDeductibles, addToPendingTasks,
  discounts, setDiscounts, computedRates, setComputedRates,
  specificAreaOfCoverage, setSpecificAreaOfCoverage, areaOfCoverage,
  setShowImageCopy }, ref) => {
  const { appConfig } = useContext(ConfigContext);

  const id = 'company';

  const companyDiscounts = getSafe(() => appConfig.content.specifics.company.company_discounts);
  const groupSizes = getSafe(() => appConfig.content.specifics.company.group_sizes);
  const MHD_Loadings = getSafe(() => appConfig.content.specifics.company.mhd_loadings);

  const [companyDiscount, setCompanyDiscount] = useState(getCompanyDiscountsLabel()[0]);
  const [groupSize, setGroupSize] = useState(getGroupSizesLabel()[0]);
  const [MHD_Loading, set_MHD_Loading] = useState(get_MHD_LoadingsLabel()[0]);


  const company_Plans = ['company_essential', 'company_classic', 'company_gold', 'company_classic_with_dental', 'company_gold_with_dental', 'company_gold_superior', 'company_gold_superior_with_dental'];

  const specific_area_of_coverages = getSafe(() => appConfig.content.set2_area_of_coverage);

  const [assistanceCovers, setAssistanceCovers] = useState({
    evacuation: false,
    repatriation: false
  });

  useEffect(() => {
    computedRates[id] = getComputedRates();
    setComputedRates(computedRates);
  }, [response, discounts, companyDiscount, groupSize, MHD_Loading]);

  function getComputedRates() {
    if (!response) return;

    const rates = response.rates;
    const discount = discounts[id];
    const totalPercent = (100 - discount) * 0.01;

    const computedRates = [];

    for (let i = 0; i < rates.length; i++) {
      computedRates.push({
        'client': rates[i].client,
        'essential': computedRate('essential', rates[i].essential) * totalPercent,
        'classic': computedRate('classic', rates[i].classic) * totalPercent,
        'gold': computedRate('gold', rates[i].gold) * totalPercent,
        'classic_with_dental': computedRate('classic_with_dental', rates[i].classic_with_dental) * totalPercent,
        'gold_with_dental': computedRate('gold_with_dental', rates[i].gold_with_dental) * totalPercent,
        'gold_superior': computedRate('gold_superior', rates[i].gold_superior) * totalPercent,
        'gold_superior_with_dental': computedRate('gold_superior_with_dental', rates[i].gold_superior_with_dental) * totalPercent,
      })
    }

    return computedRates;
  }


  function checkIfThereIsSelected_Company_Plan() {
    for (let i = 0; i < selectedPlans.length; i++) {
      if (company_Plans.includes(selectedPlans[i])) {
        return true;
      }
    }

    return false;
  }

  function getCompanyDiscountsLabel() {
    const labels = [];

    for (let companyDiscount in companyDiscounts) {
      labels.push(companyDiscount);
    }

    return labels;
  }

  function getGroupSizesLabel() {
    const labels = [];

    for (let groupSize in groupSizes) {
      labels.push(groupSize);
    }

    return labels;
  }

  function get_MHD_LoadingsLabel() {
    const labels = [];

    for (let MHD_Loading in MHD_Loadings) {
      labels.push(MHD_Loading);
    }

    return labels;
  }

  function getDeductibleIndex(deductible) {
    const deductibles = getSafe(() => appConfig.content.deductibles.company[currency]);

    for (let i = 0; i < deductibles.length; i++) {
      if (deductible === deductibles[i]) {
        return 'D' + i;
      }
    }

    return 'D0';
  }

  function computeCompanyRates(rates) {
    const computedRates = [];

    for (let i = 0; i < rates.length; i++) {
      computedRates.push({
        'client': rates.client,
        'essential': computedRate('essential', rates[i].essential),
        'classic': computedRate('classic', rates[i].classic),
        'gold': computedRate('gold', rates[i].gold),
        'classic_with_dental': computedRate('classic_with_dental', rates[i].classic_with_dental),
        'gold_with_dental': computedRate('gold_with_dental', rates[i].gold_with_dental),
        'gold_superior': computedRate('gold_superior', rates[i].gold_superior),
        'gold_superior_with_dental': computedRate('gold_superior_with_dental', rates[i].gold_superior_with_dental),
      })
    }

    return computedRates;
  }

  function computedRate(plan, rate) {
    if (rate === 'Unavailable' || typeof rate === 'string') {
      return rate;
    }

    const MHD_LoadingValue = MHD_Loadings[MHD_Loading];
    const companyDiscountValue = companyDiscounts[companyDiscount][plan];
    const groupSizeValue = groupSizes[groupSize];

    let total = rate;

    // MHD loadings
    total = total * (1 + MHD_LoadingValue);

    // company discount
    total = total * (1 - companyDiscountValue);

    // group size discount
    total = total * (1 - groupSizeValue);

    return total;
  }

  function getCompanyDiscountLabel(plan) {
    const val = companyDiscounts[companyDiscount][plan];

    return (val * 100).toFixed(0) + '%'
  }

  function onInputDiscountChange(e) {
    var numericValue = e.target.value.replace(/[^0-9]/g, '');

    // Remove leading zeros
    numericValue = numericValue.replace(/^0+/, '');

    if (numericValue > 100) {
      numericValue = 100;
    }

    discounts[id] = numericValue;

    setDiscounts({ ...discounts });
  }

  return (
    <Box sx={sx}>
      <Paper sx={{ borderColor: '#e0e0e0', borderWidth: 2, pb: 2 }} variant='outlined' >
        <Typography variant='h5' sx={{ color: '#fff', background: '#00bcd4', px: 2, py: 1 }}>BUPA Company</Typography>

        <Box sx={{ p: 2 }}>
          {/* <Grid container alignItems='center'>
            <Grid item xs={1.5}>
              <Typography fontSize={18} fontWeight={600}>Select Plans</Typography>
            </Grid>
            <Grid item xs={1.2}>
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='company_essential' />} label="Essential" />
            </Grid>
            <Grid item xs={1.2}>
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='company_classic' />} label="Classic" />
            </Grid>
            <Grid item xs={1}>
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='company_gold' />} label="Gold" />
            </Grid>
            <Grid item xs={1.3}>
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='company_classic_with_dental' />} label="Classic with Dental" />
            </Grid>

            <Grid item xs={1.3}>
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='company_gold_with_dental' />} label="Gold with Dental" />
            </Grid>
            <Grid item xs={1.3}>
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='company_gold_superior' />} label="Gold Superior" />
            </Grid>
            <Grid item xs={1.3}>
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='company_gold_superior_with_dental' />} label="Gold Superior with Dental" />
            </Grid>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={1.5}>
              <Typography fontSize={18} fontWeight={600}>Optional</Typography>
            </Grid>
            <Grid item xs={1.2}>
              <FormControlLabel control={<Checkbox checked={assistanceCovers.evacuation}
                onChange={(e) => { setAssistanceCovers({ ...assistanceCovers, evacuation: e.target.checked }) }} />}
                label="Evacuation" />
            </Grid>
            <Grid item xs={1.2}>
              <FormControlLabel control={<Checkbox checked={assistanceCovers.repatriation}
                onChange={(e) => { setAssistanceCovers({ ...assistanceCovers, repatriation: e.target.checked }) }} />}
                label="Repatriation" />
            </Grid>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={1.5}>
              <Typography fontSize={18} fontWeight={600}>Deductible</Typography>
            </Grid>
            <Grid item xs={1.7}>
              <CustomSelect2
                value={deductibles[id].label}
                onChange={(e) => {
                  // setDeductible(e.target.value);

                  // setDeductibleIndex(getDeductibleIndex(e.target.value));

                  const val = e.target.value;

                  const _deductibles = JSON.parse(JSON.stringify(deductibles));

                  _deductibles[id].label = val;
                  _deductibles[id].deductible_index = getDeductibleIndex(val);

                  setDeductibles(_deductibles);

                  addToPendingTasks([id]);

                  // handleUpdateLoadingStatus(true);
                  // setLoading(true);
                  // setTimeout(() => {
                  //   ref.current.handleGetQuotation();
                  // }, 200);

                }}
                items={getSafe(() => appConfig.content.deductibles.company[currency], [])}
                disabled={loadingStatus[id]}
              />
            </Grid>
            <Grid item xs={1}>
              <Typography fontSize={18} fontWeight={600}>Company Discount</Typography>
            </Grid>
            <Grid item xs={1.6}>
              <CustomSelect2
                value={companyDiscount}
                onChange={(e) => {
                  setCompanyDiscount(e.target.value);
                }}
                items={getCompanyDiscountsLabel()}
                disabled={loadingStatus[id]}
              />
            </Grid>
            <Grid item xs={1}>
              <Typography fontSize={18} fontWeight={600}>Group Size</Typography>
            </Grid>
            <Grid item xs={1}>
              <CustomSelect2
                value={groupSize}
                onChange={(e) => {
                  setGroupSize(e.target.value);
                }}
                items={getGroupSizesLabel()}
                disabled={loadingStatus[id]}
              />
            </Grid>
            <Grid item xs={1.2}>
              <Typography fontSize={18} fontWeight={600}>MHD Loading</Typography>
            </Grid>
            <Grid item xs={1}>
              <CustomSelect2
                value={MHD_Loading}
                onChange={(e) => {
                  set_MHD_Loading(e.target.value);
                }}
                items={get_MHD_LoadingsLabel()}
                disabled={loadingStatus[id]}
              />
            </Grid>

            <Grid item xs={2}>
              <Typography fontSize={18}>
                MHD Loading = <span display='inline-block' style={{ fontSize: 18, color: '#00bcd4', fontWeight: 600 }}>{MHD_Loadings[MHD_Loading] * 100}%</span>
              </Typography>
            </Grid>
            <Grid item xs={1.5}><Typography fontSize={18} fontWeight={600}>Area Of Coverage</Typography></Grid>
            <Grid item xs={2}>
              <Select
                size='small'
                value={specificAreaOfCoverage[id]}
                onChange={(e) => {
                  specificAreaOfCoverage[id] = e.target.value;
                  setSpecificAreaOfCoverage({ ...specificAreaOfCoverage });

                  addToPendingTasks([id]);
                }}
                sx={{ fontSize: 12, height: 40, width: '190px' }}
                fullWidth
                disabled={loadingStatus[id]}
              >
                <MenuItem value={'parent'} sx={{ color: colors.blue[500] }}>{areaOfCoverage}</MenuItem>
                {
                  specific_area_of_coverages && specific_area_of_coverages.map(area_of_coverage => (
                    <MenuItem sx={{ display: areaOfCoverage === area_of_coverage ? 'none' : 'block' }} key={area_of_coverage} value={area_of_coverage}>{area_of_coverage}</MenuItem>
                  ))
                }

              </Select>
            </Grid>
            <Grid item xs={1.5}><Typography fontSize={18} fontWeight={600}>Renewal/Further Discount</Typography></Grid>
            <Grid item xs={1}>
              <TextField
                size='small'
                sx={{ width: '80px', textAlign: 'end' }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                value={discounts[id]}
                onChange={onInputDiscountChange}
              />
            </Grid>
          </Grid> */}

          <Box sx={{ display: 'flex', gap: 5 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography fontSize={18} fontWeight={600}>Plan Type</Typography>
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='company_essential' />} label="Essential" />
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='company_classic' />} label="Classic" />
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='company_gold' />} label="Gold" />
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='company_classic_with_dental' />} label="Classic with Dental" />
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='company_gold_with_dental' />} label="Gold with Dental" />
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='company_gold_superior' />} label="Gold Superior" />
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='company_gold_superior_with_dental' />} label="Gold Superior with Dental" />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography fontSize={18} fontWeight={600}>Assistance Cover</Typography>
              <FormControlLabel control={<Checkbox checked={assistanceCovers.evacuation}
                onChange={(e) => { setAssistanceCovers({ ...assistanceCovers, evacuation: e.target.checked }) }} />}
                label="Evacuation" />
              <FormControlLabel control={<Checkbox checked={assistanceCovers.repatriation}
                onChange={(e) => { setAssistanceCovers({ ...assistanceCovers, repatriation: e.target.checked }) }} />}
                label="Repatriation" />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Deductible Option</Typography>
                <CustomSelect2
                  sx={{ minWidth: 190 }}
                  value={deductibles[id].label}
                  onChange={(e) => {
                    // setDeductible(e.target.value);

                    // setDeductibleIndex(getDeductibleIndex(e.target.value));

                    const val = e.target.value;

                    const _deductibles = JSON.parse(JSON.stringify(deductibles));

                    _deductibles[id].label = val;
                    _deductibles[id].deductible_index = getDeductibleIndex(val);

                    setDeductibles(_deductibles);

                    addToPendingTasks([id]);

                    // handleUpdateLoadingStatus(true);
                    // setLoading(true);
                    // setTimeout(() => {
                    //   ref.current.handleGetQuotation();
                    // }, 200);

                  }}
                  items={getSafe(() => appConfig.content.deductibles.company[currency], [])}
                  disabled={loadingStatus[id]}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Area of Coverage</Typography>
                <Select
                  size='small'
                  value={specificAreaOfCoverage[id]}
                  onChange={(e) => {
                    specificAreaOfCoverage[id] = e.target.value;
                    setSpecificAreaOfCoverage({ ...specificAreaOfCoverage });

                    addToPendingTasks([id]);
                  }}
                  sx={{ fontSize: 12, height: 40, width: '190px' }}
                  fullWidth
                  disabled={loadingStatus[id]}
                >
                  <MenuItem value={'parent'} sx={{ color: colors.blue[500] }}>{areaOfCoverage}</MenuItem>
                  {
                    specific_area_of_coverages && specific_area_of_coverages.map(area_of_coverage => (
                      <MenuItem sx={{ display: areaOfCoverage === area_of_coverage ? 'none' : 'block' }} key={area_of_coverage} value={area_of_coverage}>{area_of_coverage}</MenuItem>
                    ))
                  }

                </Select>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Company Discount</Typography>
                <CustomSelect2
                  sx={{ minWidth: 190 }}
                  value={companyDiscount}
                  onChange={(e) => {
                    setCompanyDiscount(e.target.value);
                  }}
                  items={getCompanyDiscountsLabel()}
                  disabled={loadingStatus[id]}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Group Size</Typography>
                <CustomSelect2
                  sx={{ minWidth: 190 }}
                  value={groupSize}
                  onChange={(e) => {
                    setGroupSize(e.target.value);
                  }}
                  items={getGroupSizesLabel()}
                  disabled={loadingStatus[id]}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>MHD Loading</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <CustomSelect2
                    sx={{ minWidth: 190 }}
                    value={MHD_Loading}
                    onChange={(e) => {
                      set_MHD_Loading(e.target.value);
                    }}
                    items={get_MHD_LoadingsLabel()}
                    disabled={loadingStatus[id]}
                  />
                  <Typography fontSize={18}>
                    <span display='inline-block' style={{ fontSize: 18, color: '#00bcd4', fontWeight: 600 }}>{MHD_Loadings[MHD_Loading] * 100}%</span>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Renewal/Further Discount</Typography>
                <TextField
                  size='small'
                  sx={{ width: '80px', textAlign: 'end' }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  value={discounts[id]}
                  onChange={onInputDiscountChange}
                />
              </Box>
            </Box>
          </Box>

          <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
            <Button
              size='small'
              sx={{ mb: 1 }}
              onClick={() => {
                var node = document.getElementById('table-company-rates');

                node.style = 'width: auto;';

                domtoimage.toBlob(node).then(function (blob) {
                  // Create ClipboardItem with blob and its type, and add to an array
                  const data = [new ClipboardItem({ [blob.type]: blob })];
                  // Write the data to the clipboard
                  navigator.clipboard.write(data);

                  setShowImageCopy(true);

                  node.style = 'width: 100%;';
                })
                  .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                    node.style = 'width: 100%;';
                  });
              }}
              endIcon={<ContentCopyIcon />}
            >Copy Image</Button>
          </div>

          <TableContainer component={Paper}>
            <Table id='table-company-rates' size='small' sx={{ minWidth: 650 }}>
              <TableHead sx={{ background: '#00bcd4', color: '#fff' }}>
                <TableRow>
                  <TableCell sx={{ width: 170, color: '#fff', fontWeight: 600 }}>Plan</TableCell>
                  <TableCell sx={{ width: 60, color: '#fff', fontWeight: 600 }}>Product Discount</TableCell>
                  {
                    clients.map((client, idx) => {
                      if (client.full_name.trim() === '') {
                        return <TableCell sx={{ width: 200, fontWeight: 600, color: '#fff' }} key={idx}>_</TableCell>
                      }

                      return <TableCell sx={{ width: 200, color: '#fff', fontWeight: 600 }} key={idx}><div>{client.full_name} ({client.age})</div><div>{client.country_of_residence}</div>  </TableCell>
                    })
                  }
                  <TableCell sx={{ color: '#fff', fontWeight: 600 }}>Subtotal</TableCell>
                </TableRow>

              </TableHead>
              <TableBody>
                <TableRatesRow
                  planId='company_essential'
                  planName='essential'
                  planLabel='Essential'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#80deea'
                  loading={loadingStatus[id]}
                  rates={response ? computeCompanyRates(response.rates) : []}
                  currency={currency}
                  additionalColumns={[{ value: getCompanyDiscountLabel('essential'), textAlign: 'center', bgColor: '#4dd0e1' }]}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('company_essential')}
                />
                <TableRatesRow
                  planId='company_classic'
                  planName='classic'
                  planLabel='Classic'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#80deea'
                  loading={loadingStatus[id]}
                  rates={response ? computeCompanyRates(response.rates) : []}
                  currency={currency}
                  additionalColumns={[{ value: getCompanyDiscountLabel('classic'), textAlign: 'center', bgColor: '#4dd0e1' }]}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('company_classic')}
                />
                <TableRatesRow
                  planId='company_gold'
                  planName='gold'
                  planLabel='Gold'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#80deea'
                  loading={loadingStatus[id]}
                  rates={response ? computeCompanyRates(response.rates) : []}
                  currency={currency}
                  additionalColumns={[{ value: getCompanyDiscountLabel('gold'), textAlign: 'center', bgColor: '#4dd0e1' }]}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('company_gold')}
                />
                <TableRatesRow
                  planId='company_classic_with_dental'
                  planName='classic_with_dental'
                  planLabel='Classic with Dental'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#80deea'
                  loading={loadingStatus[id]}
                  rates={response ? computeCompanyRates(response.rates) : []}
                  currency={currency}
                  additionalColumns={[{ value: getCompanyDiscountLabel('classic_with_dental'), textAlign: 'center', bgColor: '#4dd0e1' }]}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('company_classic_with_dental')}
                />
                <TableRatesRow
                  planId='company_gold_with_dental'
                  planName='gold_with_dental'
                  planLabel='Gold with Dental'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#80deea'
                  loading={loadingStatus[id]}
                  rates={response ? computeCompanyRates(response.rates) : []}
                  currency={currency}
                  additionalColumns={[{ value: getCompanyDiscountLabel('gold_with_dental'), textAlign: 'center', bgColor: '#4dd0e1' }]}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('company_gold_with_dental')}
                />
                <TableRatesRow
                  planId='company_gold_superior'
                  planName='gold_superior'
                  planLabel='Gold Superior'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#80deea'
                  loading={loadingStatus[id]}
                  rates={response ? computeCompanyRates(response.rates) : []}
                  currency={currency}
                  additionalColumns={[{ value: getCompanyDiscountLabel('gold_superior'), textAlign: 'center', bgColor: '#4dd0e1' }]}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('company_gold_superior')}
                />
                <TableRatesRow
                  planId='company_gold_superior_with_dental'
                  planName='gold_superior_with_dental'
                  planLabel='Gold Superior Dental'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#80deea'
                  loading={loadingStatus[id]}
                  rates={response ? computeCompanyRates(response.rates) : []}
                  currency={currency}
                  additionalColumns={[{ value: getCompanyDiscountLabel('gold_superior_with_dental'), textAlign: 'center', bgColor: '#4dd0e1' }]}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('company_gold_superior_with_dental')}
                />
                {
                  (assistanceCovers.evacuation || assistanceCovers.repatriation) &&
                  <TableRow>
                    <TableCell colSpan={clients.length + 3} sx={{ fontWeight: 600 }}>Optional</TableCell>
                  </TableRow>
                }

                {/* <TableRow>
                  <TableCell colSpan={clients.length + 3} sx={{ fontWeight: 600 }}>Optional</TableCell>
                </TableRow> */}

                <TableRatesRow
                  planId='company_evacuation'
                  planName='evacuation'
                  planLabel='Evacuation (Annually)'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#eeeeee'
                  loading={loadingStatus[id]}
                  rates={response ? response.assistance_covers : []}
                  currency={currency}
                  planLabelColSpan={2}
                  visible={assistanceCovers.evacuation}
                />

                <TableRatesRow
                  planId='company_repatriation'
                  planName='repatriation'
                  planLabel='Repatriation (Annually)'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#eeeeee'
                  loading={loadingStatus[id]}
                  rates={response ? response.assistance_covers : []}
                  currency={currency}
                  planLabelColSpan={2}
                  visible={assistanceCovers.repatriation}
                />
                {/* {
                  checkIfThereIsSelected_Company_Plan() == false &&
                  <TableRow>
                    <TableCell>No Plan Selected</TableCell>
                  </TableRow>
                } */}
              </TableBody>
            </Table>

          </TableContainer>


        </Box>


      </Paper>

    </Box>
  );
});

export default Company_Rates;