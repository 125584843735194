import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import Main from '../components/Main';
import MainHeader from '../components/MainHeader'
import { AuthContext } from '../utils/AuthContext';
import { deleteQuotationData, fetchAllQuotationData } from '../services/QuotationDataService';
import moment from 'moment';
import AlertDialog from '../components/AlertDialog';
import { toast } from 'react-toastify';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import useDebounce from '../utils/UseDebounce';

function QuotationList() {

  const { auth, setAuth } = useContext(AuthContext);

  const [open, setOpen] = useState(localStorage.getItem('quoting-drawer-open') == 'true');

  const [quotations, setQuotations] = useState([]);

  const [fetchingQuotations, setFetchingQuotations] = useState(true);
  const [quotationToDelete, setQuotationToDelete] = useState();
  const [showDeleteQuotation, setShowDeleteQuotation] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    handleFetchAllQuotationData();
  }, []);

  // DeBounce Function
  useDebounce(() => {
    handleFetchAllQuotationData();
  }, [searchText], 800
  );

  async function handleFetchAllQuotationData() {
    setFetchingQuotations(true);

    const response = await fetchAllQuotationData({ token: auth.token, search: searchText });

    setQuotations(response.data.quotations);

    setFetchingQuotations(false);
  }

  async function handleDeleteQuotationData(quotationId) {
    setDeleteLoading(true);

    await deleteQuotationData({ token: auth.token, quotationId });

    toast.success('Quotation Deleted', {
      autoClose: 1500,
      hideProgressBar: true,
    });

    setDeleteLoading(false);

    handleFetchAllQuotationData();
  }


  return (
    <Box sx={{ display: 'flex', background: '#f9f9f9', height: '100vh' }}>
      <MainHeader open={open} setOpen={setOpen} />
      <Main open={open} sx={{ pt: 7, }} >
        <Typography variant='h4' sx={{ fontWeight: 600, mt: 4, mb: 2 }}>Saved Quotations</Typography>
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Search..."
            variant="standard"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }} />
        </Box>

        {/* Table */}
        <Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  fetchingQuotations === true ?
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <CircularProgress />
                        </Box>
                      </TableCell>
                    </TableRow>
                    :
                    (
                      quotations.length == 0 ? 
                      <TableRow>
                        <TableCell colSpan={3} sx={{ textAlign: 'center' }}>No Quotation</TableCell>
                      </TableRow>
                        :
                        quotations.map((quotation) => (
                          <TableRow
                            key={quotation.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              {quotation.name}
                            </TableCell>
                            <TableCell>{moment(quotation.created_at).format('lll')}</TableCell>
                            <TableCell>
                              <Button variant='text' size='small' onClick={() => {
                                setQuotationToDelete(quotation);
                                setShowDeleteQuotation(true);
                              }}
                                disabled={deleteLoading}>Delete</Button>
                              <Button sx={{ ml: 2 }} variant='contained' component={Link} to={"/quotation?id=" + quotation.id} size='small'>View</Button>

                            </TableCell>
                          </TableRow>
                        )
                        ))
                }



              </TableBody>
            </Table>
          </TableContainer>

          {/* Dialogs */}
          <AlertDialog title='Please confirm' content={'Do you want to delete ' + '`' + (quotationToDelete != null ? quotationToDelete.name : '') + '`?'}
            positiveButtonLabel='Yes' negativeButtonLabel='Cancel' open={showDeleteQuotation} setOpen={setShowDeleteQuotation}
            callback={() => {
              setShowDeleteQuotation(false);
              handleDeleteQuotationData(quotationToDelete.id)
            }} />
        </Box>
      </Main>
    </Box>
  )
}

export default QuotationList