import { FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";
import zeroDeductibleUniform from "../utils/zeroDeductibleUniform";

export default function CustomSelect2({ items, sx, style, value, onChange, disabled = false, readOnly = false, isZeroDeductibleUniform = true }) {


  return (
    <FormControl sx={sx}>
      <Select
        size="small"
        value={value}
        onChange={onChange}
        sx={{ fontSize: 14 }}
        input={<OutlinedInput />}
        // renderValue={(selected) => {
        //   if (selected == undefined || selected.length === 0) {
        //     return <em>Select</em>;
        //   }

        //   return selected;
        // }}
        displayEmpty
        style={style}
        disabled={disabled}
        readOnly={readOnly}
      >
        <MenuItem disabled value="">
          <em>Select</em>
        </MenuItem>
        {items && items.map((item, index) => (
          <MenuItem key={index} value={item}>
            {
              isZeroDeductibleUniform ? zeroDeductibleUniform(item) : item
            }
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}