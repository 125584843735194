import { Checkbox } from '@mui/material';

export default function SelectPlanCheckbox({ planId, selectedPlans, setSelectedPlans, readOnly = false, onChangeCb = null }) {

  function handleOnChange(e) {
    if (readOnly) { return; }

    const checked = e.target.checked;
    const idx = selectedPlans.indexOf(planId);

    if (checked == true) {
      if (idx == -1) {
        selectedPlans.push(planId);
      }
    } else {
      selectedPlans.splice(idx, 1);
    }

    setSelectedPlans([...selectedPlans]);

    if (typeof onChangeCb === 'function') {
      onChangeCb();
    }
  }

  return (
    <Checkbox checked={selectedPlans.includes(planId)} onChange={handleOnChange} />
  );
}