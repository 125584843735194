import { Button, TextField, Typography } from "@mui/material"
import { Box, Container } from "@mui/system"
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { NavLink } from 'react-router-dom';
import { AuthContext } from "../utils/AuthContext";
import { login } from "../services/AuthController";
import { fetchAppConfig } from "../services/AppConfigService";
import { ConfigContext } from "../utils/ConfigContext";
import { LoadingButton } from "@mui/lab";

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { auth, setAuth } = useContext(AuthContext);
  const { setAppConfig } = useContext(ConfigContext);

  useEffect(() => {
    if (auth.token) {
      handleNavigate();
    }
  }, []);

  function handleNavigate() {
    navigate('/quotation');
  }

  async function handleLogin(event) {
    event.preventDefault();

    if (loading === true) {
      // prevent clicking the button while loading
      return;
    }
    
    // const toastId = toast.loading("Please Wait...");

    try {
      setLoading(true);

      const response = await login({ email, password }).catch(err => {
        setLoading(false);
      });

      const user = response.data.user;
      const token = response.data.token;

      localStorage.setItem('quoting-api-token', token);
      localStorage.setItem('quoting-current-user', JSON.stringify(user));

      setAuth({
        token,
        user
      });

      await handleFetchAppConfig({ token: token }).catch(err => {
        setLoading(false);
      });
      

      setTimeout(() => {
        // toast.update(toastId, {
        //   type: 'success',
        //   render: 'Logged in',
        //   autoClose: 1000,
        //   hideProgressBar: true,
        //   isLoading: false
        // });
  
        setLoading(false);
        handleNavigate();
      }, 200);

    } catch (err) {
      toast.error("Incorrect Email or Password", {
        autoClose: 1000,
        hideProgressBar: true,
      });
    }
  }

  async function handleFetchAppConfig({ token }) {
    const res = await fetchAppConfig({ token: token });

    const config = res.data;

    const configCurrentTimestamp = localStorage.getItem('quoting-app-config-timestamp');

    if (configCurrentTimestamp !== config.timestamp) {
      localStorage.setItem('quoting-app-config-timestamp', config.timestamp);
      localStorage.setItem('quoting-app-config', config.content);

      setAppConfig({
        content: JSON.parse(config.content),
        configTimestamp: config.timestamp
      });
    }
  }


  return (
    <>
      <div className="full-height">
        <Container sx={{ mt: 0, p: '20px 0 28px', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 2px 4px, rgba(0, 0, 0, 0.23) 0px 2px 4px;', pb: 5 }} component="main" maxWidth="xs">
          <Typography align="center">
            <img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="" id="logo" />
          </Typography>
          <Typography sx={{ mt: -3 }} component="h1" variant="h4" align="center">
            Medishure Quoting
          </Typography>

          <Box component="form" method="POST" noValidate onSubmit={handleLogin} sx={{ mt: 3 }}>
            <TextField
              sx={{ mt: 2 }}
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => { setEmail(e.target.value) }}
            />

            <TextField
              sx={{ mt: 2 }}
              required
              fullWidth
              id="password"
              label="Password"
              name="password"
              type="password"
              value={password}
              onChange={(e) => { setPassword(e.target.value) }}
            />
            <LoadingButton 
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3 }}
              size="large"
              loadingPosition="start"
              loading={loading}>Login</LoadingButton>
            {/* <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3 }}
              size="large"
            >
              Login
            </Button> */}
            <NavLink to="/forgot">
              <Typography sx={{ mt: 1, textAlign: 'center' }}>
                Forgot password?
              </Typography>
            </NavLink>
          </Box>
        </Container>
      </div>
    </>
  )
}

export default Login;