// export default {
//   baseURL: 'http://localhost:8000',
//   storageURL: 'http://localhost:8000',
//   ROLE_SUPER_ADMIN: 0,
//   ROLE_ADMIN: 1,
//   ROLE_USER: 2,
// }

export default {
  baseURL: 'https://mib-quotetool.com',
  storageURL: 'https://mib-quotetool.com/quoting_storage',
  ROLE_SUPER_ADMIN: 0,
  ROLE_ADMIN: 1,
  ROLE_USER: 2,
}