import ApiClient from "./ApiClient";

export const uploadFile = ({ body, token }) => {
  return ApiClient.post('/api/files/upload_file', body, {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })
}


export const fetchFiles = ({  token }) => {
  return ApiClient.get('/api/files', {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })
}

export const downloadFile = ({ id, token }) => {
  return ApiClient.get('/api/files/download_file?id=' + id, {
    headers: {
      'Authorization': 'Bearer ' + token
    },
    responseType: 'blob'
  })
}