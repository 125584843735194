import { Box, Button, Chip, CircularProgress, Paper, Skeleton, Typography } from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import Main from '../components/Main';
import MainHeader from '../components/MainHeader'
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';

import { AuthContext } from '../utils/AuthContext';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ErrorIcon from '@mui/icons-material/Error';
import { styled } from '@mui/material/styles';
import AlertDialog from '../components/AlertDialog';
import global from "../utils/Global";
import { downloadFile, fetchFiles, uploadFile } from '../services/AppFileService';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import AppFileCard from '../components/AppFileCard';
import Global from '../utils/Global';


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function AppFiles() {

  const { auth, setAuth } = useContext(AuthContext);

  const [open, setOpen] = useState(localStorage.getItem('quoting-drawer-open') == 'true');

  const [file, setFile] = useState(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [fetchingFiles, setFetchingFiles] = useState(false);

  const [fileId, setFileId] = useState(false);
  const [fileDescription, setFileDescription] = useState('');

  const [openUploadDialog, setOpenUploadDialog] = useState(false);

  const [files, setFiles] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (auth.user.role_id === Global.ROLE_ADMIN || auth.user.role_id === Global.ROLE_SUPER_ADMIN) {
      // User is authorized, do nothing
    } else {
      // User is not authorized, redirect
      navigate('/quotation');
    }
  }, []);

  useEffect(() => {
    handleFetchFiles();
  }, []);

  useEffect(() => {
    if (file != null) {
      setOpenUploadDialog(true);
    }

  }, [file]);

  async function handleFetchFiles() {
    setFetchingFiles(true);

    const response = await fetchFiles({ token: auth.token });

    setFiles(response.data.app_files);

    setFetchingFiles(false);
  }


  function handleUploadFile() {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('id', fileId);

    setFileUploading(true);

    const toastId = toast.loading("Uploading...");

    uploadFile({ body: formData, token: auth.token }).then(() => {
      toast.update(toastId, {
        type: 'success',
        render: fileDescription + ' Uploaded',
        // autoClose: 2500,
        closeButton: true,
        hideProgressBar: true,
        isLoading: false
      });

      setFileUploading(false);

      handleFetchFiles();
    })
      .catch(err => {
        setFileUploading(false);
      });
  }

  function handleDownloadFile(id) {
    // window.location.href = global.baseURL + '/quoting_api/api/files/download_file?id=' + id;
    downloadFile({ id, token: auth.token })
      .then((response) => {
        const str = response.headers['content-disposition'];

        const match = str.match(/filename=([^;]+)/);
        let filename = 'file.xlsx';
        if (match) {
          filename = match[1].trim();
        } else {
          console.log('Filename not found.');
        }

        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  }

  function getLastUpdate(id) {
    const file = files.find(el => {
      return el.id === id;
    });

    if (!file) {
      return false;
    }

    return moment(file.uploaded_at).format('lll');
  }

  return (
    <Box sx={{ display: 'flex', background: '#f9f9f9', height: '100vh' }}>
      <MainHeader open={open} setOpen={setOpen} />
      <Main open={open} sx={{ pt: 7, }} >
        <h1>App Files</h1>

        <AppFileCard id='bupa_zones'
          title='BUPA COUNTRY ZONES'
          setFileId={setFileId}
          setFile={setFile}
          uploadButtonTitle='Upload File'
          downloadButtonTitle='View File'
          fileId={fileId}
          isUploading={fileUploading}
          setShowUploadDialog={setOpenUploadDialog}
          getLastUpdate={getLastUpdate}
          downloadFile={handleDownloadFile}
          fetchingFiles={fetchingFiles}
          setFileDescription={setFileDescription}
          sx={{ mt: 2 }}
        />

        <AppFileCard id='vumi_zones'
          title='VUMI COUNTRY ZONES'
          setFileId={setFileId}
          setFile={setFile}
          uploadButtonTitle='Upload File'
          downloadButtonTitle='View File'
          fileId={fileId}
          isUploading={fileUploading}
          setShowUploadDialog={setOpenUploadDialog}
          getLastUpdate={getLastUpdate}
          downloadFile={handleDownloadFile}
          fetchingFiles={fetchingFiles}
          setFileDescription={setFileDescription}
          sx={{ mt: 2 }}
        />

        <AppFileCard id='optimum_global_zones'
          title='OPTIMUM GLOBAL COUNTRY ZONES'
          setFileId={setFileId}
          setFile={setFile}
          uploadButtonTitle='Upload File'
          downloadButtonTitle='View File'
          fileId={fileId}
          isUploading={fileUploading}
          setShowUploadDialog={setOpenUploadDialog}
          getLastUpdate={getLastUpdate}
          downloadFile={handleDownloadFile}
          fetchingFiles={fetchingFiles}
          setFileDescription={setFileDescription}
          sx={{ mt: 2 }}
        />

        {/* <Paper sx={{ padding: 2 }}>
          <Typography variant='h5' sx={{ color: '#42a5f5' }}>BUPA COUNTRY ZONES</Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Button onClick={() => {
              setFile(null);
              setFileId('');
            }} size='small' component="label" variant="contained" startIcon={<CloudUploadIcon />}>
              Upload Zones
              {
                (fileUploading === true && fileId == 'bupa_zones') &&
                <CircularProgress size='18px' sx={{ color: '#fff', ml: 1 }} />
              }

              <VisuallyHiddenInput type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => {
                setFile(e.target.files[0]);
                setOpenUploadDialog(false);
                setFileId('bupa_zones');

                e.target.value = null;
              }}
              />
            </Button>
            <Chip label='View Zones'
              onClick={() => { downloadFile('bupa_zones'); }}
              icon={<FileDownloadIcon />}
              sx={{ ml: 2 }} />
          </Box>
          <Box sx={{ mt: 2 }}>
            {
              fetchingFiles ? <CircularProgress size={20} /> :
                <Typography>Last Update: {getLastUpdate('bupa_zones')}</Typography>
            }
          </Box>

        </Paper> */}

        {/* 
        <Paper sx={{ padding: 2, mt: 2 }}>
          <Typography variant='h5' sx={{ color: '#42a5f5' }}>UNIVERSAL OUTPUT</Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Button onClick={() => {
              setFile(null);
              setFileId('');
            }} size='small' component="label" variant="contained" startIcon={<CloudUploadIcon />}>
              Upload File
              {
                (fileUploading === true && fileId == 'universal_output') &&
                <CircularProgress size='18px' sx={{ color: '#fff', ml: 1 }} />
              }

              <VisuallyHiddenInput type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => {
                setFile(e.target.files[0]);
                setOpenUploadDialog(false);
                setFileId('universal_output');

                e.target.value = null;
              }}
              />
            </Button>
            <Chip label='View File'
              onClick={() => { downloadFile('universal_output'); }}
              icon={<FileDownloadIcon />}
              sx={{ ml: 2 }} />
          </Box>
          <Box sx={{ mt: 2 }}>
            {
              fetchingFiles ? <CircularProgress size={20} /> :
                <Typography>Last Update: {getLastUpdate('universal_output')}</Typography>
            }
          </Box>


        </Paper> */}
        <AppFileCard id='universal_output'
          title='TABLE OF BENEFITS'
          setFileId={setFileId}
          setFile={setFile}
          uploadButtonTitle='Upload File'
          downloadButtonTitle='View File'
          fileId={fileId}
          isUploading={fileUploading}
          setShowUploadDialog={setOpenUploadDialog}
          getLastUpdate={getLastUpdate}
          downloadFile={handleDownloadFile}
          fetchingFiles={fetchingFiles}
          setFileDescription={setFileDescription}
          sx={{ mt: 2 }}
        />

        <AppFileCard id='app_config'
          title='APP CONFIG'
          setFileId={setFileId}
          setFile={setFile}
          uploadButtonTitle='Upload File'
          downloadButtonTitle='View File'
          fileId={fileId}
          isUploading={fileUploading}
          setShowUploadDialog={setOpenUploadDialog}
          getLastUpdate={getLastUpdate}
          downloadFile={handleDownloadFile}
          fetchingFiles={fetchingFiles}
          setFileDescription={setFileDescription}
          fileType='json'
          sx={{ mt: 2 }}
        />
      </Main>

      <AlertDialog title={'Upload File (' + fileDescription + ')'} content={'Please confirm to upload the file <span style="font-weight:600; color:#ffc107;">' + (file ? file.name : '') + '</span>'}
        positiveButtonLabel='Confirm' negativeButtonLabel='Cancel' open={openUploadDialog} setOpen={setOpenUploadDialog}
        callback={() => {
          handleUploadFile();
          setOpenUploadDialog(false);
        }} />
    </Box>
  )
}

export default AppFiles